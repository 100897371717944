import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { BreadcrumbLink, MultimediaTabs } from '../components'
import { Config } from '../config'

const Author = () => {
    const location = useLocation();

    let { url } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [multimedia, setMultimedia] = useState([]);

    useEffect(() => {
        fetch(Config.API_URL + 'authors/detail?a=' + url)
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setMultimedia(data);

                    Config.updateMetaTitle('Autores - ' + url);
                    Config.updateMetaDescription('Accede a los materiales de consulta disponibles de este autor');
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, [location,url]);

    if (error) {
      return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        return (
            <div className="container">
                <section id="microsites">
                    <div className="breadcrumb">
                        <BreadcrumbLink path="" text="Inicio" />
                        <BreadcrumbLink path="autores" text="Autores" />
                        <h1>{url}</h1>
                    </div>

                    <div className="row">
                        <div className="col-12">
                          <MultimediaTabs keyword={url} multimedia={multimedia} noElements={() => {return (<p className="text-center h4 mt-3">No se encontró multimedia para el autor.</p>);}} />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default Author;
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default class BreadcrumbLink extends Component {
    render() {
        return (
            <Link to={`/${this.props.path}`}>{this.props.text} <FontAwesomeIcon icon={faChevronRight} /></Link>
        )
    }
}
import React, { useEffect, useState, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loading, SectionCard } from '../../components'
import { Config } from '../../config'

const SectionCardPaged = (props) => {
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(10);
    const [fetching, setFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);
    const [urlChange, setUrlChange] = useState(0); // Contador para resetear imágenes cuando el url cambia

    const isFirstRender = useRef(true);

    // useEffect(() => {
    //     loadData();
    // },[]);

    useEffect(() => {
        loadData();
    },[urlChange]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }

        setItems([]);
        setPage(0);
        setFetching(false);
        setHasMore(true);
        setUrlChange(urlChange + 1);
    },[props.url,props.params]);

    const loadData = () => {
        if(fetching) return;
    
        setFetching(true);
        
        fetch(Config.API_URL + props.url + '?p='+page+'&l='+length)
            .then(res => res.json())
            .then(
                (data) => {
                    setItems([...items, ...data]);
                    setPage(page + 1);

                    if(data.length < length) setHasMore(false);

                    setFetching(false);
                },
                (error) => {
                    setFetching(false);
                    setError(error);
                }
            );
    };
   
    return (
        <InfiniteScroll
            dataLength={items.length}
            next={loadData}
            hasMore={hasMore}
            loader={<Loading />}
        >
            <div className="row">
                {items.length?'':<p className="text-center h4 mt-3">Por el momento, no hay eventos publicados.</p>}
                {items.map((item,index) => {
                    var date = Config.formatLiveDate(item);
                    return (
                      <div className="col-12 col-md-4 col-lg-3" key={`live-${index}`}>
                        <SectionCard classes="card-essential" section="en-directo" url={`evento/${item.url}`} name={item.name} image={item.image} imagepath="lives" description={date} />
                      </div>
                    );
                })}
            </div>
        </InfiniteScroll>
    )
}

export default SectionCardPaged;
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbLink, ChildrenMediaCard } from '../components'
import { Config } from '../config'

const ChildrenVideos = () => {
    const location = useLocation();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        Config.updateMetaTitle('Infancias - Videos');
        Config.updateMetaDescription('Esta sección alberga videos especialmente dedicados al aprendizaje y entretenimiento de las infancias.');
    },[]);

    useEffect(() => {
      fetch(Config.API_URL + 'children/videos?p=0&l=10')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setVideos(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          );
    }, [location]);

    let main = <div className="row">
                  {videos.length?'':<p className="text-center h4 mt-3">Por el momento, no hay videos.</p>}   
                  {videos.map((videos,index) => {
                    return (
                        <div className="col-12 col-md-4" key={`videos-${index}`}>
                            <ChildrenMediaCard description={videos.length?"Duración: "+videos.length:""} classes="card-essential" imagepath="children" 
                            image={videos.image} id={videos.id} title={videos.title} file={videos.file} author={videos.author} 
                            name={videos.title} section="" />
                        </div>
                    );
                  })}
                </div>

    let currentContent = main;

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        return (
            <div className="container">
                <section id="children-videos">
                    <div className="breadcrumb">
                      <BreadcrumbLink path="" text="Inicio" />
                      <BreadcrumbLink path="infancias" text="Infancias" />
                      <h1>Videos</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-2">
                          <div className="list-group d-none d-lg-block">
                            <Link key={`children-option-stories`} to={`/infancias/cuentos`} className={"list-group-item list-group-item-action"}>
                                Cuentos
                            </Link>
                            {/* <Link key={`children-option-images`} to={`/infancias/imagenes`} className={"list-group-item list-group-item-action"}>
                                Imágenes
                            </Link> */}
                            <Link key={`children-option-books`} to={`/infancias/libros`} className={"list-group-item list-group-item-action"}>
                                Libros
                            </Link>
                            <Link key={`children-option-music`} to={`/infancias/musica`} className={"list-group-item list-group-item-action"}>
                                Música
                            </Link>
                            <Link key={`children-option-radio`} to={`/infancias/radio`} className={"list-group-item list-group-item-action"}>
                                Radio
                            </Link>
                            <Link key={`children-option-videos`} to={`/infancias/videos`} className={"list-group-item list-group-item-action active"}>
                                Videos
                            </Link>
                          </div>
                        </div>
                        <div className="col-12 col-lg-10">
                            {currentContent}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default ChildrenVideos;
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { BreadcrumbLink } from '../components'
import { Config } from '../config'

const LiveEvent = () => {
    const location = useLocation();

    let { url } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentLive, setCurrentLive] = useState(null);

    useEffect(() => {
      fetch(Config.API_URL + 'lives/detail/' + url)
          .then(res => res.json())
          .then(
            (data) => {
                setCurrentLive(data);
                setIsLoaded(true);
            },
            (error) => {
                setError(error);
                setIsLoaded(true);
            }
          );
    }, [location, url]);

    if (error) {
       return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
       return <div className="text-center mt-5">Cargando...</div>;
    } else {
      var subtitle = <p>{Config.formatLiveDate(currentLive)}</p>;
      var content = currentLive.code?<div dangerouslySetInnerHTML={{__html:currentLive.code}}></div>:<iframe src={currentLive.link} title={currentLive.name} width="100%"></iframe>;
      if (currentLive.start>Config.getCurrentDate()) {
        subtitle = <p><strong className="text-primary">Próximamente disponible: </strong>{Config.formatLiveDate(currentLive)}</p>;
        content = <img className="mb-5" src={`${Config.API_URL}lives/image/${currentLive.image}`} alt={currentLive.name} width="100%"/>
      };

      let currentContent = <div className="iframe-live" >
                              {subtitle}
                              {content}
                          </div>;

      return (
        <div>
            <div className="container">
                <section id="live">
                    <div className="breadcrumb">
                        <BreadcrumbLink path="" text="Inicio" />
                        <BreadcrumbLink path="desde-la-fonoteca" text="Desde la Fonoteca" />
                        <BreadcrumbLink path="desde-la-fonoteca/videos" text="Videos" />
                        <h1>{currentLive.name}</h1>
                    </div>
                    {currentContent}
                </section>
            </div>
        </div>
      );
    }
}

export default LiveEvent;
import React from 'react'
import ReactGA from "react-ga4"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { Config } from '../../config'
import useSharedFormState from '../../store';

const DetailButton = (props) => {
    const { setAreMediaDetailsOpen, setMultimediaDetails, setKeyword, setAltAuthorColumnTitle, setAltYearColumnTitle} = useSharedFormState();

    const showAudioDetail = () => {
        fetch(Config.API_URL + 'multimedia/detail/' + props.id)
          .then(res => res.json())
          .then(
              (data) => {
                    setMultimediaDetails(data);
                    setKeyword(props.keyword);
                    setAltAuthorColumnTitle(props.altAuthorColumnTitle);
                    setAltYearColumnTitle(props.altYearColumnTitle);
                    setAreMediaDetailsOpen(true);
    
                    ReactGA.event({
                        category: 'Multimedia',
                        action: 'Ver ficha',
                        value: parseInt(props.id),
                        label: data.internal_id
                    });
              },
              (error) => {
                setAreMediaDetailsOpen(false);
              }
          );
    }

    return (
        props.content?
        <button className={'btn-detail'} onClick={showAudioDetail}>{props.content}</button>:
        <button className={'btn-detail'} onClick={showAudioDetail}>
            <FontAwesomeIcon icon={faEllipsisH} size="2x" />
        </button>
    )
}

export default DetailButton;
import React from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const NotFound = () => {
    
    return (
        <div>
            <div className="container pt-5">
                <h6 className='text-center mb-5'>Lo sentimos, la página que buscas no existe.</h6>
                <p className='text-center'>
                    <Link to={`/`} className='btn btn-primary'><FontAwesomeIcon icon={faHome} /> Ir al incio</Link>
                </p>
            </div>
        </div>
    );

}

export default NotFound;
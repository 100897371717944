import React from 'react'
import Modal from 'react-modal';
import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
import { Config } from '../../config'
import useSharedFormState from '../../store';

const VideoModal = () => {
    const { videoIsOpen, setIsVideoOpen, video } = useSharedFormState();

    function afterOpenModal() { }

    function closeModal() {
        setIsVideoOpen(false);
    }

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        borderRadius: '0',
        width: '65%'
      },
    };
    const replayOptions = {
        videoStreamer: {
          hlsjs: {
            customConfiguration: {
              capLevelToPlayerSize: true,
              maxBufferLength: 45
            }
          }
        }
    };
    
    return (
        <Modal isOpen={videoIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Reproducción de video">
            <button className="btn btn-link btn-close" onClick={closeModal}></button>
            <h5><span>{video.title}</span></h5>

            {video.file?(
              <Replay
                  source={`${Config.VIDEOS_URL}/vod/mp4:${video.file}/playlist.m3u8`}
                  options={replayOptions}
              >
                  <HlsjsVideoStreamer />
              </Replay>
            ):false}
        </Modal>
    )
}

export default VideoModal;
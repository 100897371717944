import React from 'react'
import Modal from 'react-modal';
import useSharedFormState from '../../store';
import { ShareButton, MediaDetail } from '../../components';
import { Config } from '../../config';

const MediaDetailModal = () => {

    const { mediaDetailsAreOpen, setAreMediaDetailsOpen, multimediaDetails , keyword } = useSharedFormState();

    function afterOpenModal() { }

    function closeModal() {
        setAreMediaDetailsOpen(false);
    }

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        borderRadius: '0',
      },
    };

    var title = multimediaDetails.title;
    if(keyword) {
        var searchRegex = Config.getRegex(keyword);
        if(title) title = title.replace(searchRegex, '<span class="highlight">$1</span>');
    }

    return (
        <Modal isOpen={mediaDetailsAreOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Detalle de audio">
            <button className="btn btn-link btn-close" onClick={closeModal}></button>
            <h4>
                <span dangerouslySetInnerHTML={{__html:title}}></span>
                <ShareButton size="1x" />

                {/* <a href="https://localcontexts.org/notice/open-to-collaborate/" target="_blank" className="btn btn-link btn-media-action" style={{ float:'right', marginRight:'10px' }} type="button" data-bs-toggle="tooltip" aria-expanded="false" title="Abierto a colaborar">
                    <img className="icono-local-contexts" src={require('../../images/local-contexts-open-to-colllaborate.png').default} height="30" alt="Open to collaborate" />
                </a> */}
            </h4>
            
            <MediaDetail />
        </Modal>
    )
}

export default MediaDetailModal;
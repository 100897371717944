import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export const Loading = () => {
    return (
        <div className="container-loading text-center mt-3">
            <FontAwesomeIcon icon={faSpinner} size="3x" className={"fa-spin mb-2 text-primary"} /><br/>
            <span>Cargando</span>
        </div>
    )
}

export default Loading;
import React, { Component } from 'react'
import { DetailButton, SeeDocumentButton, TotalCountItems } from '../../components'

export default class DocumentsTable extends Component {

    showFile = (id) => {
        document.getElementById('show-file-'+id).click();
    }
    
    render() {
        if(this.props.items.length) {
            return (
                <div className="table-responsive">
                    <table className="table table-documents table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                {this.props.musicSheets?<th>Obra / Título</th>:<th>Título</th>}
                                {this.props.historicArchives?null:(this.props.musicSheets?<th>Compositor / Autor</th>:<th>Autor</th>)}
                                {this.props.publications?<th>Editor / Productor</th>:null}
                                {this.props.historicArchives?<th>Año</th>:<th>Año de edición</th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.items.map((item,index) => {
                                if(!item.file) return null;
                                
                                var title = item.title?item.title:'<em>N/A</em>';
                                var author = item.author?item.author:'<em>N/A</em>';
                                var editor = item.editor?item.editor:'<em>N/A</em>';
                                var year = item.year?item.year:'<em>N/A</em>';
                                
                                if(this.props.keyword) {
                                    var searchRegex = new RegExp('('+this.props.keyword+')' , 'ig');
                                    if(title) title = title.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(author) author = author.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(editor) editor = editor.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(year) year = year.replace(searchRegex, '<span class="highlight">$1</span>');
                                }
                                return (
                                <tr key={`row-item-${index}`} filter={item.filter} onClick={() => this.showFile(item.id)}>
                                    <td className="document-actions"><SeeDocumentButton id={item.id} title={item.title} file={item.file} author={item.author} altYearColumnTitle={this.props.historicArchives}/></td>
                                    <td dangerouslySetInnerHTML={{__html:title}}></td>
                                    {this.props.historicArchives?null:<td dangerouslySetInnerHTML={{__html:author}}></td>}
                                    {this.props.publications?<td dangerouslySetInnerHTML={{__html:editor}}></td>:null}
                                    <td dangerouslySetInnerHTML={{__html:year}}></td>
                                    <td><DetailButton id={item.id} altAuthorColumnTitle={this.props.publications} altYearColumnTitle={this.props.historicArchives}/></td>
                                </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <TotalCountItems items={this.props.items}/>
                </div>
            )
        } else {
            return (<p className="text-center h4 mt-3">Por el momento, no hay información.</p>)
        }
    }
}
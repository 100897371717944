import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen, faMusic, faBroadcastTower, faVideo, faBook } from '@fortawesome/free-solid-svg-icons'
import { BreadcrumbLink } from '../components'
import { Config } from '../config'

const Children = () => {
    const description = 'Esta sección alberga programas de radio, libros, música y videos especialmente dedicados al aprendizaje y entretenimiento de las infancias.';
    Config.updateMetaTitle('Infancias');
    Config.updateMetaDescription(description);

    return (
        <div className="container">
            <section id="children">
                <div className="breadcrumb">
                    <BreadcrumbLink path="" text="Inicio" />
                    <h1>Infancias</h1>
                </div>
                <div className="mb-2">
                    <blockquote>{description}</blockquote>
                </div>
                <div className="row mt-5 mb-5 d-flex justify-content-center text-center">
                    <div className="col-6 col-md-3 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/infancias/cuentos`}>
                            <FontAwesomeIcon icon={faBookOpen} size="6x" />
                            <h2>Cuentos</h2>
                        </Link>
                    </div>
                    {/* <div className="col-6 col-md-3 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/infancias/imagenes`}>
                            <FontAwesomeIcon icon={faImages} size="6x" />
                            <h2>Imágenes</h2>
                        </Link>
                    </div> */}
                    <div className="col-6 col-md-3 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/infancias/libros`}>
                            <FontAwesomeIcon icon={faBook} size="6x" />
                            <h2>Libros</h2>
                        </Link>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/infancias/musica`}>
                            <FontAwesomeIcon icon={faMusic} size="6x" />
                            <h2>Música</h2>
                        </Link>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/infancias/radio`}>
                            <FontAwesomeIcon icon={faBroadcastTower} size="6x" />
                            <h2>Radio</h2>
                        </Link>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/infancias/videos`}>
                            <FontAwesomeIcon icon={faVideo} size="6x" />
                            <h2>Videos</h2>
                        </Link>
                    </div>
                </div>
            </section> 
        </div>
    );
}

export default Children;
import React from 'react'
import ReactGA from "react-ga4"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Config } from '../../config'

const DownloadButton = (props) => {

    return (
        <a className={'btn-download'} href={`${Config.DOCUMENTS_URL}${props.file}`} download={props.file}>
            <FontAwesomeIcon icon={faDownload} size="2x" />
        </a>
    )
}

export default DownloadButton;
import React from 'react'
import useSharedFormState from '../../store';
import { Config } from '../../config';
import { Link } from 'react-router-dom';

const MediaDetail = () => {

    const { multimediaDetails , keyword , altAuthorColumnTitle } = useSharedFormState();
    
    // Detalles de la media
    var origin_id = multimediaDetails.origin_id;
    var source = multimediaDetails.source;
    var collection = multimediaDetails.collection?multimediaDetails.collection.name:null;
    var movement = multimediaDetails.movement;
    var album = multimediaDetails.album;
    var author = multimediaDetails.author;
    var performer = multimediaDetails.performer;
    var soloist = multimediaDetails.soloist;
    var director = multimediaDetails.director;
    var editing_place = multimediaDetails.editing_place;
    var editor = multimediaDetails.editor;
    var year = multimediaDetails.year;
    var credits = multimediaDetails.credits;
    var instrumental = multimediaDetails.instrumental;
    var indigenous_people = multimediaDetails.indigenous_people;
    var musical_gender = multimediaDetails.musical_gender;
    var musical_region = multimediaDetails.musical_region;
    var recording_place = multimediaDetails.recording_place;
    var native_music = multimediaDetails.native_music;
    var length = multimediaDetails.length;
    var language = multimediaDetails.language;
    var notes = multimediaDetails.notes;
    var comments = multimediaDetails.comments;
    
    if(keyword) {
        var searchRegex = Config.getRegex(keyword);
        if(origin_id) origin_id = origin_id.replace(searchRegex, '<span class="highlight">$1</span>');
        if(movement) movement = movement.replace(searchRegex, '<span class="highlight">$1</span>');
        if(album) album = album.replace(searchRegex, '<span class="highlight">$1</span>');
        if(author) author = author.replace(searchRegex, '<span class="highlight">$1</span>');
        if(performer) performer = performer.replace(searchRegex, '<span class="highlight">$1</span>');
        if(soloist) soloist = soloist.replace(searchRegex, '<span class="highlight">$1</span>');
        if(director) director = director.replace(searchRegex, '<span class="highlight">$1</span>');
        if(editing_place) editing_place = editing_place.replace(searchRegex, '<span class="highlight">$1</span>');
        if(editor) editor = editor.replace(searchRegex, '<span class="highlight">$1</span>');
        if(year) year = year.replace(searchRegex, '<span class="highlight">$1</span>');
        if(credits) credits = credits.replace(searchRegex, '<span class="highlight">$1</span>');
        if(instrumental) instrumental = instrumental.replace(searchRegex, '<span class="highlight">$1</span>');
        if(indigenous_people) indigenous_people = indigenous_people.replace(searchRegex, '<span class="highlight">$1</span>');
        if(musical_gender) musical_gender = musical_gender.replace(searchRegex, '<span class="highlight">$1</span>');
        if(musical_region) musical_region = musical_region.replace(searchRegex, '<span class="highlight">$1</span>');
        if(recording_place) recording_place = recording_place.replace(searchRegex, '<span class="highlight">$1</span>');
        if(native_music) native_music = native_music.replace(searchRegex, '<span class="highlight">$1</span>');
        if(length) length = length.replace(searchRegex, '<span class="highlight">$1</span>');
        if(language) language = language.replace(searchRegex, '<span class="highlight">$1</span>');
        if(notes) notes = notes.replace(searchRegex, '<span class="highlight">$1</span>');
        if(comments) comments = comments.replace(searchRegex, '<span class="highlight">$1</span>');
        
        if(source) source = source.replace(searchRegex, '<span class="highlight">$1</span>');
        if(collection) collection = collection.replace(searchRegex, '<span class="highlight">$1</span>');
    }

    if(comments) comments = '<p><span class="collapsed-lines">'+comments+'</span><a class="btn-link" onclick="this.previousSibling.classList.remove(\'collapsed-lines\');this.remove();">Leer más</a></p>';

    let rowComments = null;
    if(multimediaDetails.comments) {
        rowComments =   <tr>
                             {/* <th>Comentario:</th> */}
                            <td colSpan="2" style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{__html:comments}}></td>
                        </tr>;                   
    }

    let rowMovement = null;
    if(multimediaDetails.movement) {
        rowMovement =   <tr>
                            <th>Movimiento:</th>
                            <td dangerouslySetInnerHTML={{__html:movement}}></td>
                        </tr>;
    }

    let rowId = null;
    if(multimediaDetails.origin_id) {
        rowId = <tr>
                    <th>Identificador de origen:</th>
                    <td dangerouslySetInnerHTML={{__html:origin_id}}></td>
                </tr>;
    }

    let rowSource = null;
    if(multimediaDetails.source) {
        rowSource =   <tr>
                            <th>Procedencia:</th>
                            <td dangerouslySetInnerHTML={{__html:source}}></td>
                        </tr>;
    }

    let rowCollection = null;
    if(multimediaDetails.collection) {
        rowCollection =   <tr>
                            <th>Colección:</th>
                            <td><Link to={`/colecciones/${multimediaDetails.collection.url}`} className="btn-link" dangerouslySetInnerHTML={{__html:collection}}></Link></td>
                        </tr>;
    }
    
    let rowAlbum = null;
    if(multimediaDetails.album) {
        rowAlbum =  <tr>
                        <th>Álbum / Serie:</th>
                        <td dangerouslySetInnerHTML={{__html:album}}></td>
                    </tr>;
    }

    let rowAuthor = null;
    if(multimediaDetails.author) {
        if(altAuthorColumnTitle) {
            rowAuthor = <tr>
                            <th>Autor:</th>
                            <td dangerouslySetInnerHTML={{__html:author}}></td>
                        </tr>;
        } else {
            rowAuthor = <tr>
                            <th>Compositor / Autor:</th>
                            <td dangerouslySetInnerHTML={{__html:author}}></td>
                        </tr>;
        }
    }

    let rowPerformer = null;
    if(multimediaDetails.performer) {
        rowPerformer =  <tr>
                            <th>Intérpretes / Participantes:</th>
                            <td dangerouslySetInnerHTML={{__html:performer}}></td>
                        </tr>;   
    }

    let rowSoloist = null;
    if(multimediaDetails.soloist) {
        rowSoloist =   <tr>
                            <th>Solista:</th>
                            <td dangerouslySetInnerHTML={{__html:soloist}}></td>
                        </tr>;
    }

    let rowDirector = null;
    if(multimediaDetails.director) {
        rowDirector =   <tr>
                            <th>Director:</th>
                            <td dangerouslySetInnerHTML={{__html:director}}></td>
                        </tr>;
    }

    let rowEditingPlace = null;
    if(multimediaDetails.editing_place) {
        rowEditingPlace =   <tr>
                                <th>Lugar de edición:</th>
                                <td dangerouslySetInnerHTML={{__html:editing_place}}></td>
                            </tr>;
    }

    let rowEditor = null;
    if(multimediaDetails.editor) {
        rowEditor = <tr>
                        <th>Editor / Productor:</th>
                        <td dangerouslySetInnerHTML={{__html:editor}}></td>
                    </tr>;
    }

    let rowYear = null;
    if(multimediaDetails.year) {
        rowYear =   <tr>
                        <th>Año:</th>
                        <td dangerouslySetInnerHTML={{__html:year}}></td>
                    </tr>;
    }

    let rowCredits = null;
    if(multimediaDetails.credits) {
        rowCredits =   <tr>
                            <th>Créditos:</th>
                            <td dangerouslySetInnerHTML={{__html:credits}}></td>
                        </tr>;
    }
    
    let rowInstrumental = null;
    if(multimediaDetails.instrumental) {
        rowInstrumental =   <tr>
                            <th>Dotación instrumental:</th>
                            <td dangerouslySetInnerHTML={{__html:instrumental}}></td>
                        </tr>;
    }
    
    let rowIndigenousPeople = null;
    if(multimediaDetails.indigenous_people) {
        rowIndigenousPeople =   <tr>
                            <th>Pueblo indígena:</th>
                            <td dangerouslySetInnerHTML={{__html:indigenous_people}}></td>
                        </tr>;
    }
    
    let rowMusicalGender = null;
    if(multimediaDetails.musical_gender) {
        rowMusicalGender =   <tr>
                            <th>Género:</th>
                            <td dangerouslySetInnerHTML={{__html:musical_gender}}></td>
                        </tr>;
    }
    
    let rowMusicalRegion = null;
    if(multimediaDetails.musical_region) {
        rowMusicalRegion =   <tr>
                            <th>Región musical:</th>
                            <td dangerouslySetInnerHTML={{__html:musical_region}}></td>
                        </tr>;
    }
    
    let rowRecordingPlace = null;
    if(multimediaDetails.recording_place) {
        rowRecordingPlace = <tr>
                                <th>Lugar o entidad de grabación:</th>
                                <td dangerouslySetInnerHTML={{__html:recording_place}}></td>
                            </tr>;
    }
    
    let rowNativeMusic = null;
    if(multimediaDetails.native_music) {
        rowNativeMusic =   <tr>
                            <th>Categoría musical nativa:</th>
                            <td dangerouslySetInnerHTML={{__html:native_music}}></td>
                        </tr>;
    }
    
    let rowLength = null;
    if(multimediaDetails.length) {
        rowLength = <tr>
                        <th>Duración:</th>
                        <td dangerouslySetInnerHTML={{__html:length}}></td>
                    </tr>;
                    //No está habilitado
    }

    let rowLanguage = null;
    if(multimediaDetails.language) {
        rowLanguage =   <tr>
                            <th>Idioma:</th>
                            <td dangerouslySetInnerHTML={{__html:language}}></td>
                        </tr>;
    }

    let rowNotes = null;
    if(multimediaDetails.notes) {
        rowNotes =  <tr>
                        <th>Notas:</th>
                        <td dangerouslySetInnerHTML={{__html:notes}}></td>
                    </tr>;
    }

    return (
        <table className="table table-audio-detail table-hover">
            <tbody>
                {rowComments}
                {rowMovement}
                {rowId}
                {rowSource}
                {rowCollection}
                {rowAlbum}
                {rowAuthor}
                {rowPerformer}
                {rowSoloist}
                {rowDirector}
                {rowEditingPlace}
                {rowEditor}
                {rowYear}
                {rowCredits}
                {rowInstrumental}
                {rowIndigenousPeople}
                {rowMusicalGender}
                {rowMusicalRegion}
                {rowRecordingPlace}
                {rowNativeMusic}
                {rowLength}
                {rowLanguage}
                {rowNotes}
            </tbody>
        </table>
    )
}

export default MediaDetail;
import React from 'react'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faLink } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import useSharedFormState from '../../store';
import { Config } from '../../config';
import { useAlert } from 'react-alert'

const ShareButton = (props) => {

    const { multimediaDetails } = useSharedFormState();
    const alert = useAlert();

    if(!multimediaDetails.file) return null;

    const shareText = "Visita Musiteca.mx para "+
                        (Config.isVideo(multimediaDetails.file) || Config.isAudio(multimediaDetails.file)?"reproducir":"ver")+" "+
                        (Config.isImage(multimediaDetails.file)?"la imagen":("el "+(Config.isVideo(multimediaDetails.file)?"video":(Config.isAudio(multimediaDetails.file)?"audio":"documento"))))+
                        " \""+multimediaDetails.title+"\"";
    const shareUrl = `${window.location.origin}/media/${multimediaDetails.internal_id}`;
    
    const copyLink = () => {
        navigator.clipboard.writeText(shareUrl);
        alert.success('Link copiado');
    };

    return (
        <div className="dropdown d-inline-block ms-2">
            <button className="btn btn-link btn-media-action" type="button" id="dropdownMenuShare" data-bs-toggle="dropdown" aria-expanded="false" title="Compartir">
                <FontAwesomeIcon icon={faShareAlt} size={props.size?props.size:"2x"} />
            </button>
            <ul className="dropdown-menu dropdown-share" aria-labelledby="dropdownMenuShare">
                <li>
                    <FacebookShareButton url={shareUrl} title={shareText} quote={shareText} hashtag={'musitecamx'}>
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </FacebookShareButton>
                </li>
                <li>
                    <TwitterShareButton url={shareUrl} title={shareText} hashtags={['musitecamx','fonotecanacional','musiteca']}>
                        <FontAwesomeIcon icon={faXTwitter} size="2x" />
                    </TwitterShareButton>
                </li>
                <li className="d-inline-block d-md-none">
                    <WhatsappShareButton url={shareUrl} title={shareText} separator=" - ">
                        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                    </WhatsappShareButton>
                </li>
                <li style={{verticalAlign:'top'}}>
                    <button className="btn p-0" style={{fontSize: '24px', lineHeight: '32px'}} onClick={copyLink} title={"Copiar link"}>
                        <FontAwesomeIcon icon={faLink} size="1x" />
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default ShareButton;
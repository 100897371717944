import { Config } from '../../config'
import useSharedFormState from '../../store';

const ChildrenMediaCard = (props) => {
    const { setVideo, setIsVideoOpen, player, audioList, setAudioList,setAreMediaDetailsOpen, setMultimediaDetails  } = useSharedFormState();

    const playAudio = () => {
        if(Config.isVideo(props.file)) {
            setVideo({
                title: props.title,
                file: props.file
            });
            setIsVideoOpen(true);
            if(player) player.pause();

            fetch(Config.API_URL + 'multimedia/reproduction/' + props.id + '?p=web')
                .then(res => res.json())
                .then(
                    (data) => {},
                    (error) => {}
                );
        } else {
            var index = -1;
            for(var i in audioList) {
                if(audioList[i].id === props.id) index = i;
            }
            if(index === -1) {
                let newAudios = [
                    ...audioList,
                    {
                        id: props.id,
                        name: props.title,
                        singer: props.author,
                        musicSrc: `${Config.ASSETS_URL}${props.file}`
                    }
                ];
                setAudioList(newAudios);
                player.playByIndex(newAudios.length - 1);
    
                fetch(Config.API_URL + 'multimedia/reproduction/' + props.id + '?p=web')
                    .then(res => res.json())
                    .then(
                        (data) => {},
                        (error) => {}
                    );
            } else {
                player.playByIndex(index);
            }
        }
    }

    const showMediaDetail = (id) => {
        fetch(Config.API_URL + 'multimedia/detail/' + id)
          .then(res => res.json())
          .then(
              (data) => {
                    setMultimediaDetails(data);
                    setAreMediaDetailsOpen(true);
              },
              (error) => {
                setAreMediaDetailsOpen(false);
              }
          )
      }

    let description = null;
    if(props.description) {
        description = <p className="card-text">{props.description}</p>;
    }

    return (
        <div className={`card card-section card-children ${props.classes}`} style={{ backgroundImage: `url(`+(props.imagepath?`${Config.API_URL}${props.imagepath}/image/`:Config.ASSETS_URL)+`${props.image})` }}>
            <button onClick={playAudio} className="card-body">
                <h5 className="card-title">{props.name}</h5>
                {description}
            </button>
            <button className="btn btn-sm btn-link" onClick={() => showMediaDetail(props.id)} style={{position:'absolute', bottom: -30}}>Ver créditos</button>
        </div>
    )
}

export default ChildrenMediaCard;
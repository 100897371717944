import React, { useState, useEffect } from 'react';
import { BreadcrumbLink, SectionCard } from '../components'
import { Config } from '../config'

const Essentials = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [essentials, setEssentials] = useState([]);
    
    const description = 'En esta sección se incluye una selección de audios representativos de las categorías que se presentan a continuación. Cada una de ellas, curada por especialistas en la materia, destaca la relevancia de sus contenidos, así como la diversidad y la riqueza de la música interpretada o producida en nuestro país.';
        
    useEffect(() => {
      Config.updateMetaTitle('Imprescindibles');
      Config.updateMetaDescription(description);

      fetch(Config.API_URL + 'essentials')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setEssentials(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
    }, []);

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        return (
          <div className="container">
              <section id="essentials">
                  <div className="breadcrumb">
                    <BreadcrumbLink path="" text="Inicio" /><h1>Imprescindibles</h1>
                  </div>
                  <div className="mb-2">
                    <blockquote>{description}</blockquote>
                  </div>
                  <div className="row">
                    {essentials.map((essential,index) => {
                      return (
                        <div className="col-12 col-md-6 col-lg-3" key={`essential-category-${index}`}>
                          <SectionCard classes="card-essential" section="imprescindibles" url={essential.url} name={essential.name} description={essential.description} image={essential.image} imagepath="categories" />
                        </div>
                      );
                    })}   
                  </div>
              </section>
          </div>
        );
    }
}
export default Essentials;
import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Slider from "react-slick";
import { BreadcrumbLink, MediaTablePaged, ImageGridPaged, DocumentsTablePaged, Loading } from '../components'
import useSharedFormState from '../store';
import { Config } from '../config'

const Collection = () => {
    const { setAreMediaDetailsOpen} = useSharedFormState();

    const location = useLocation();

    let { url } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [collections, setCollections] = useState([]);
    const [currentCollection, setCurrentCollection] = useState(null);

    useEffect(() => {
      Config.updateMetaTitle('Colecciones');

      setAreMediaDetailsOpen(false);

      fetch(Config.API_URL + 'collections')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setCollections(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          );

      if(url) {
        fetch(Config.API_URL + 'collections/detail/' + url)
            .then(res => res.json())
            .then(
                (data) => {
                    setCurrentCollection(data);

                    Config.updateMetaTitle('Colecciones - '+data.name);
                    Config.updateMetaDescription('Conoce el contenido facilitado por esta institución.');

                    var tabs = document.getElementById('nav-tab');
                    if(tabs) {
                      tabs = tabs.children;
                      // Array.from(tabs).forEach(function(el) {
                      //   el.classList.remove('active');
                      // });
                      // tabs[0].classList.add('active');
                      if(tabs.length) tabs[0].click();
                    }
                },
                (error) => {
                    setError(error);
                }
            );
      }
    }, [location,url]);

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        let main = <div className="row">
                    {collections.map((collection,index) => {
                      return (
                        <div className="col-12 col-sm-6 col-lg-4" key={`collection-${index}`}>
                          <Link to={`/colecciones/${collection.url}`} className="card-body">
                              <img width="100%" preserveAspectRatio="xMidYMid slice" focusable="false" src={Config.API_URL + 'collections/image/' + collection.image} alt={collection.name} />
                            </Link>
                        </div>
                      );
                    })}
                  </div>

        let currentContent = main;

        if(currentCollection) {

            currentContent =  <div>
                          <div className="text-center mt-4">
                            <img src={`${Config.API_URL}collections/image/${currentCollection.image}`} className="collection-logo" alt={currentCollection.name}/>
                          </div>
                          <nav className="mt-3">
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                              {currentCollection.audios?<button className={"nav-link"} id="nav-audios-tab" data-bs-toggle="tab" data-bs-target="#nav-audios" type="button" role="tab" aria-controls="nav-audios">Audios</button>:null}
                              {currentCollection.icono?<button className={"nav-link"} id="nav-icono-tab" data-bs-toggle="tab" data-bs-target="#nav-icono" type="button" role="tab" aria-controls="nav-icono">Iconografías</button>:null}
                              {currentCollection.music_sheets?<button className={"nav-link"} id="nav-music-sheets-tab" data-bs-toggle="tab" data-bs-target="#nav-music-sheets" type="button" role="tab" aria-controls="nav-music-sheets">Partituras</button>:null}
                              {currentCollection.publications?<button className={"nav-link"} id="nav-publications-tab" data-bs-toggle="tab" data-bs-target="#nav-publications" type="button" role="tab" aria-controls="nav-publications">Publicaciones</button>:null}
                              {currentCollection.videos?<button className={"nav-link"} id="nav-videos-tab" data-bs-toggle="tab" data-bs-target="#nav-videos" type="button" role="tab" aria-controls="nav-videos">Videos</button>:null}
                              {currentCollection.historic_archives?<button className={"nav-link"} id="nav-historic-archives-tab" data-bs-toggle="tab" data-bs-target="#nav-historic-archives" type="button" role="tab" aria-controls="nav-historic-archives">Archivos Históricos</button>:null}
                            </div>
                          </nav>
                          <div className="tab-content" id="nav-tabContent">
                            {currentCollection.audios?
                            <div className={"tab-pane"} id="nav-audios" role="tabpanel" aria-labelledby="nav-audios-tab">
                              <MediaTablePaged url={'collections/audios/'+url} collection={true} source={url==='fonoteca-nacional'}></MediaTablePaged>
                            </div>:null}
                            {currentCollection.icono?
                            <div className={"tab-pane"} id="nav-icono" role="tabpanel" aria-labelledby="nav-icono-tab">
                              <ImageGridPaged url={'collections/icono/'+url} />
                            </div>:null}
                            {currentCollection.music_sheets?
                            <div className={"tab-pane"} id="nav-music-sheets" role="tabpanel" aria-labelledby="nav-icono-tab">
                              <DocumentsTablePaged url={'collections/musicsheets/'+url} collection={true}></DocumentsTablePaged>
                            </div>:null}
                            {currentCollection.publications?
                            <div className={"tab-pane"} id="nav-publications" role="tabpanel" aria-labelledby="nav-icono-tab">
                              <DocumentsTablePaged url={'collections/publications/'+url} collection={true}></DocumentsTablePaged>
                            </div>:null}
                            {currentCollection.videos?
                            <div className={"tab-pane"} id="nav-videos" role="tabpanel" aria-labelledby="nav-videos-tab">
                              <MediaTablePaged url={'collections/videos/'+url} collection={true} video={true}></MediaTablePaged>
                            </div>:null}
                            {currentCollection.historic_archives?
                            <div className={"tab-pane"} id="nav-historic-archives" role="tabpanel" aria-labelledby="nav-historic-archives-tab">
                              <DocumentsTablePaged url={'collections/historicarchives/'+url} collection={true}></DocumentsTablePaged>
                            </div>:null}
                          </div>
                        </div>;
        }

        const sliderSettings = {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1
        };

        return (
          <div className="container">
              <section id="collections">
                  <div className="breadcrumb">
                    <BreadcrumbLink path="" text="Inicio" />
                    <BreadcrumbLink path="colecciones" text="Colecciones" />
                    {currentCollection && currentCollection.name?<h1>{currentCollection.name}</h1>:null}
                  </div>

                  <div className="row">
                      <div className="col-12 col-lg-2 order-2 order-lg-1">
                          <div className="list-group d-none d-lg-block">
                            {collections.map((collection,indexm) => {
                              return (
                                <Link key={`collection-option-${indexm}`} to={`/colecciones/${collection.url}`} className={"mb-3 list-group-item list-group-item-action"+(currentCollection && currentCollection.id === collection.id?" active":"")}>
                                  <img width="100%" preserveAspectRatio="xMidYMid slice" focusable="false" src={Config.API_URL + 'collections/image/' + collection.image} alt={collection.name} />
                                </Link>
                              );
                            })}
                          </div>
                          <Slider {...sliderSettings} slidesToScroll={1} className="d-block d-lg-none">
                            {collections.map((collection,index) => {
                              return (
                                <div key={`collection-slider-${index}`}>
                                  <Link to={`/colecciones/${collection.url}`} className="card-body">
                                    <img width="100%" preserveAspectRatio="xMidYMid slice" focusable="false" src={Config.API_URL + 'collections/image/' + collection.image} alt={collection.name} />
                                  </Link>
                                </div>
                              );
                            })}   
                          </Slider>
                      </div>

                      <div className="col-12 col-lg-10 order-1 order-lg-2">
                          {currentContent}
                      </div>
                  </div>
              </section>
          </div>
        );
    }
}

export default Collection;
import React, { useEffect, useState, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loading, DetailButton, SeeDocumentButton, TotalCountItems, DownloadButton } from '../../components'
import { Config } from '../../config'

const DocumentsTablePaged = (props) => {
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(10);
    const [fetching, setFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);
    const [urlChange, setUrlChange] = useState(0); // Contador para resetear imágenes cuando el url cambia

    const isFirstRender = useRef(true);

    // useEffect(() => {
    //     loadData();
    // },[]);

    useEffect(() => {
        loadData();
    },[urlChange]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }

        setItems([]);
        setPage(0);
        setFetching(false);
        setHasMore(true);
        setUrlChange(urlChange + 1);
    },[props.url,props.params]);

    const loadData = () => {
        if(fetching) return;
    
        setFetching(true);
        
        fetch(Config.API_URL + props.url + '?p='+page+'&l='+length+(props.params?'&'+props.params:''))
            .then(res => res.json())
            .then(
                (data) => {
                    setItems([...items, ...data]);
                    setPage(page + 1);

                    if(data.length < length) setHasMore(false);

                    setFetching(false);
                },
                (error) => {
                    setFetching(false);
                    setError(error);
                }
            );
    };

    const showFile = (id) => {
        document.getElementById('show-file-'+id).click();
    }
    
    return (
        <InfiniteScroll
            dataLength={items.length}
            next={loadData}
            hasMore={hasMore}
            loader={<Loading />}
        >
            <div className="table-responsive">
                <table className="table table-documents table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            {props.musicSheets?<th>Obra / Título</th>:<th>Título</th>}
                            {props.historicArchives?null:(props.musicSheets?<th>Compositor / Autor</th>:<th>Autor</th>)}
                            {props.publications?<th>Editor / Productor</th>:null}
                            {props.historicArchives?<th>Año</th>:<th>Año de edición</th>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item,index) => {
                            if(!item.file) return null;
                            
                            var title = item.title?item.title:'<em>N/A</em>';
                            var author = item.author?item.author:'<em>N/A</em>';
                            var editor = item.editor?item.editor:'<em>N/A</em>';
                            var year = item.year?item.year:'<em>N/A</em>';
                            if(props.keyword) {
                                var searchRegex = new RegExp('('+props.keyword+')' , 'ig');
                                if(title) title = title.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(author) author = author.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(editor) editor = editor.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(year) year = year.replace(searchRegex, '<span class="highlight">$1</span>');
                            }
                            return (
                            <tr key={`row-item-${index}`} filter={item.filter} onClick={() => showFile(item.id)}>
                                <td className="document-actions"><SeeDocumentButton id={item.id} title={item.title} file={item.file} author={item.author} altYearColumnTitle={props.historicArchives}/></td>
                                <td dangerouslySetInnerHTML={{__html:title}}></td>
                                {props.historicArchives?null:<td dangerouslySetInnerHTML={{__html:author}}></td>}
                                {props.publications?<td dangerouslySetInnerHTML={{__html:editor}}></td>:null}
                                <td dangerouslySetInnerHTML={{__html:year}}></td>
                                <td className={props.downloadable?'multiple-actions':''}>
                                    {props.downloadable?
                                        <DownloadButton title={item.title} file={item.file} />:null}
                                    <DetailButton id={item.id} altAuthorColumnTitle={props.publications} altYearColumnTitle={props.historicArchives}/>
                                </td>
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
                <TotalCountItems items={items}/>
            </div>
        </InfiniteScroll>
    );
}

export default DocumentsTablePaged;
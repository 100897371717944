import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { BreadcrumbLink, MediaTable, SectionCardPaged } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { Config } from '../config';
// import { faYoutube } from '@fortawesome/free-brands-svg-icons';

const FromHome = () => {
  const location = useLocation();

  let { url } = useParams();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastLive, setLastLive] = useState([]);
  const [multimedia, setMultimedia] = useState([]);
  const [lives, setLives] = useState([]);
  
  useEffect(() => {
    Config.updateMetaTitle('Desde la Fonoteca');
    Config.updateMetaDescription('Aquí encontrarás sesiones de escucha, conferencias, conciertos, presentaciones y otros eventos que tienen lugar en la Fonoteca Nacional.');
  }, []);

  useEffect(() => {
    if(url === 'audios') {
      setLives(null);

      fetch(Config.API_URL + 'from-home')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setMultimedia(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          )
      } else if(url === 'videos') {
        setMultimedia(null);

        fetch(Config.API_URL + 'lives?p=0&l=12')
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setLives(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        );
      } else {
        setMultimedia(null);
        setLives(null);

        fetch(Config.API_URL + 'lives/last')
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setLastLive(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        );
      }
  }, [location,url]);
  
  let currentContent = null;

  if(multimedia) {
    currentContent = <section id="from-home-audios">
                      <MediaTable items={multimedia} fromHome={true}></MediaTable>
                    </section>;
  } else if(lives) {
    currentContent = <SectionCardPaged url={'lives'}></SectionCardPaged>;
  } else {
    var lastLiveContent = lastLive.code?<div dangerouslySetInnerHTML={{__html:lastLive.code}}></div>:<iframe src={lastLive.link} title={lastLive.name} height="100%"></iframe>;

    currentContent = <div class="row mb-5">
                      <div class="col-12 col-md-6" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <h2 style={{ marginTop: '15px', marginBottom: '15px' }}>Descubre todo lo que sucede en la Fonoteca Nacional</h2>
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <Link to="/desde-la-fonoteca/audios" className="btn btn-secondary btn-fromhome">
                              <FontAwesomeIcon icon={faMusic} size="1x" /> Escucha las producciones
                            </Link>
                          </div>
                          <div class="col-12 col-md-6">
                            <Link to="/desde-la-fonoteca/videos" className="btn btn-secondary btn-fromhome">
                              <FontAwesomeIcon icon={faPlayCircle} size="1x" /> Revive los eventos
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6" style={{textAlign: 'center', display: 'flex', alignItems: 'center'}}>
                        <div className="frame-from-home" style={{position: 'relative'}}>
                          <button className="btn btn-alt btn-fromhome btn-lastevent" onClick={(e) => {e.target.style.display = 'none'}}>
                            <FontAwesomeIcon icon={faPlayCircle} size="1x" /> Reproduce el último evento
                          </button>
                          {lastLiveContent}
                        </div>
                      </div>
                    </div>;
  }

  if (error) {
    return <div className="text-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
      return <div className="text-center mt-5">Cargando...</div>;
  } else {
    return (
      <div className="container" id="inicio-desde-la-fonoteca">
        <section>
          <div className="breadcrumb">
              <BreadcrumbLink path="" text="Inicio" />
              {multimedia || lives?<BreadcrumbLink path="desde-la-fonoteca" text="Desde la Fonoteca" />:<h1>Desde la Fonoteca</h1>}
              {multimedia?<h1>Audios</h1>:(lives?<h1>Videos</h1>:null)}
          </div>
          <div className="mb-2">
            <blockquote>
              Aquí encontrarás {lives?'videograbaciones de':(multimedia?'producciones de la Fonoteca Nacional, tales como':null)} sesiones de escucha, conferencias, 
              {lives?'':' conciertos,'} presentaciones {!multimedia?'y otros eventos que tienen lugar en la Fonoteca Nacional.':'de discos, pódcast temáticos y emisiones de la revista sonora.'}
            </blockquote>
          </div>
        </section>
        {currentContent}
      </div>
    );

  }
}

export default FromHome;
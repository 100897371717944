import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { BreadcrumbLink, SectionCard, MediaTable, ImageGrid, DocumentsTable } from '../components'
import { Config } from '../config'

const Books = () => {
    const location = useLocation();

    let { url } = useParams();

    // console.log('URL: '+url);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [books, setBooks] = useState([]);
    const [currentBook, setCurrentBook] = useState(null);
    
    const description = 'Cada libro está agrupado por categoría y contiene información de interés, iconografías, una selección de audios y su archivo pdf.';
    
    useEffect(() => {
      Config.updateMetaTitle('Libros');
      Config.updateMetaDescription(description);

      fetch(Config.API_URL + 'books')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setCategories(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          );
    }, []);

    useEffect(() => {
      fetch(Config.API_URL + 'books/ordered')
          .then(res => res.json())
          .then(
              (data) => {
                  setBooks(data);
              },
              (error) => {
                  setError(error);
              }
          );
    }, []);

    useEffect(() => {
      if(url) {
        const searchParams = new URLSearchParams(document.location.search);
        const novalidar = searchParams.get('novalidar') === '1';
        fetch(Config.API_URL + 'books/detail/' + url+(novalidar?'?novalidar=1':''))
            .then(res => res.json())
            .then(
                (data) => {
                  setCurrentBook(data);

                  Config.updateMetaTitle('Libros - '+data.name);
                  Config.updateMetaImage(`${Config.API_URL}books/image/${data.image}`);
                  if(data.abstract) Config.updateMetaDescription(data.abstract);
                },
                (error) => {
                    setError(error);
                }
            );
      } else {
        setCurrentBook(null);
      }
    }, [location,url]);

    const searchParams = new URLSearchParams(document.location.search);
    const isFrame = searchParams.get('f') === '1';

    let currentContent = null;

    if(currentBook) {
      let iconoActive = !currentBook.intro;
      let audiosActive = !currentBook.intro && !currentBook.icono.length;
      let publicationsActive = !currentBook.intro && !currentBook.icono.length && !currentBook.audios.length;

      currentContent =  <div className="row">
                          {isFrame?null:
                          <div className="col-12 col-lg-2">
                            <div className="list-group d-none d-lg-block">
                              {books.map((book,index) => {
                                return (
                                  <Link key={`book-option-${index}`} to={`/libros/${book.url}`} className={"list-group-item list-group-item-action"+(currentBook && currentBook.name === book.name?" active":"")}>
                                    {book.name}
                                  </Link>
                                );
                              })}
                            </div>
                          </div>}

                          <div className={"col-12 "+(isFrame?"":"col-lg-10")}>
                            <div className="card">
                              <div className={"row g-0 "+(currentBook.abstract?"":"justify-content-center")}>
                                <div className={"col-md-4 col-lg-"+(currentBook.abstract?"2":"3")}>
                                  <img src={`${Config.API_URL}books/image/${currentBook.image}`} className={"img-fluid rounded"+(currentBook.abstract?"-start":"")} alt="..." />
                                </div>
                                {currentBook.abstract?
                                <div className="col-md-8 col-lg-10">
                                  <div className="card-body d-flex align-items-center h-100">
                                    <p className="card-text">{currentBook.abstract}</p>
                                  </div>
                                </div>:null}
                              </div>
                            </div>
                            <nav className="mt-4">
                              <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                {currentBook.intro?<button className={"nav-link active"} id="nav-intro-tab" data-bs-toggle="tab" data-bs-target="#nav-intro" type="button" role="tab" aria-controls="nav-intro" aria-selected="true">{currentBook.introl?currentBook.introl:'Introducción'}</button>:null}
                                {currentBook.icono.length?<button className={"nav-link"+(iconoActive?" active":"")} id="nav-icono-tab" data-bs-toggle="tab" data-bs-target="#nav-icono" type="button" role="tab" aria-controls="nav-icono" aria-selected={iconoActive?"true":"false"}>Iconografías</button>:null}
                                {currentBook.audios.length?<button className={"nav-link"+(audiosActive?" active":"")} id="nav-audios-tab" data-bs-toggle="tab" data-bs-target="#nav-audios" type="button" role="tab" aria-controls="nav-audios" aria-selected={audiosActive?"true":"false"}>Audios</button>:null}
                                {currentBook.publications.length?<button className={"nav-link"+(publicationsActive?" active":"")} id="nav-publications-tab" data-bs-toggle="tab" data-bs-target="#nav-publications" type="button" role="tab" aria-controls="nav-publications" aria-selected={publicationsActive?"true":"false"}>Texto</button>:null}
                              </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                              {currentBook.intro?<div className="tab-pane fade show active" id="nav-intro" role="tabpanel" aria-labelledby="nav-intro-tab" dangerouslySetInnerHTML={{__html:currentBook.intro}}></div>:null}
                              {currentBook.icono.length?
                                <div className={"tab-pane fade"+(iconoActive?" show active":"")} id="nav-icono" role="tabpanel" aria-labelledby="nav-icono-tab">
                                  {isFrame && currentBook.name==='José Alfredo Jiménez'?<p style={{textAlign:'right'}}><em>Imágenes cortesía de José Alfredo Jiménez Gálvez</em></p>:null}
                                  <ImageGrid multimedia={currentBook.icono} />
                                </div>:null}
                              {currentBook.audios.length?
                                <div className={"tab-pane fade"+(audiosActive?" show active":"")} id="nav-audios" role="tabpanel" aria-labelledby="nav-audios-tab">
                                  <MediaTable items={currentBook.audios} microsite={true}></MediaTable>
                                </div>:null}
                              {currentBook.publications.length?
                                <div className={"tab-pane fade"+(publicationsActive?" show active":"")} id="nav-publications" role="tabpanel" aria-labelledby="nav-publications-tab">
                                  <DocumentsTable items={currentBook.publications} />
                                </div>:null}
                            </div>
                          </div>
                        </div>;
    } else {
      currentContent = <div id="accordion-index-categories" className="accordion accordion-borderless mb-5">
                        {Object.keys(categories).map((category,index) => {
                          return (
                            <div className="accordion-item" key={`category-${index}`}>
                              <h2 className="accordion-header" id={`heading-index-category-${index}`}>
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-index-category-${index}`} aria-expanded="true" aria-controls={`collapse-index-category-${index}`}>
                                  {category}
                                </button>
                              </h2>
                              <div id={`collapse-index-category-${index}`} className="accordion-collapse collapse show" aria-labelledby={`heading-index-category-${index}`} data-bs-parent="#accordion-index-categories">
                                <div className="accordion-body">
                                  <div className="row">
                                    {categories[category].map((book,indexm) => {
                                      return (
                                        <div className="col-12 col-md-4 col-lg-3" key={`book-${indexm}`}>
                                          <SectionCard section="libros" url={book.url} name={book.name} image={book.image} imagepath="books" />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>;
    }

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        return (
            <div className="container">
                <section id="books">
                    <div className="breadcrumb">
                      {isFrame?<a href="https://fonotecanacional.gob.mx/" className="logo-fonoteca-frame"><img src={require('../images/logo-fonoteca-15anios-negro.png').default} alt="Fonoteca Nacional" width={150} /></a>:null}
                      {isFrame?null:<BreadcrumbLink path="" text="Inicio" />}
                      {isFrame?null:(currentBook && currentBook.name?<BreadcrumbLink path="libros" text="Libros" />:<h1>Libros</h1>)}
                      {currentBook && currentBook.name?<h1>{currentBook.name}</h1>:null}
                    </div>
                    {currentBook && currentBook.name?null:
                      <div className="mb-2">
                        {/* <blockquote>{description}</blockquote> */}
                      </div>
                    }
                    {currentContent}
                </section>
            </div>
        );
    }
}
export default Books;
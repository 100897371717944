import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbLink } from '../components'
import { Config } from '../config'

const Collections = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [collections, setCollections] = useState([]);

  const description = 'Esta sección alberga la totalidad de los materiales disponibles en Musiteca.mx, mismos que han sido cordialmente facilitados por las siguientes instituciones. En estas colecciones se encuentra representado un importante acervo de audios, iconografías y videos, así como publicaciones y de carácter histórico.';

  useEffect(() => {
      Config.updateMetaTitle('Colecciones');
      Config.updateMetaDescription(description);
  },[]);

  useEffect(() => {
    fetch(Config.API_URL + 'collections')
        .then(res => res.json())
        .then(
            (data) => {
                setIsLoaded(true);
                setCollections(data);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
  }, []);

  if (error) {
    return <div className="text-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
      return <div className="text-center mt-5">Cargando...</div>;
  } else {

    return (
        <div className="container">
            <section id="collections">
                <div className="breadcrumb">
                    <BreadcrumbLink path="" text="Inicio" />
                    <h1>Colecciones</h1>
                </div>
                <div className="mb-2">
                  <blockquote>{description}</blockquote>
                </div>
                <div className="row mb-5 d-flex justify-content-center">
                  {collections.map((collection,index) => {
                    return (
                      <div key={`c-${index}`} className="col-12 col-md-4 col-lg-2 d-flex align-items-center">
                        <Link to={`/colecciones/${collection.url}`} className="card-body shadow collection">
                          <img width="100%" preserveAspectRatio="xMidYMid slice" focusable="false" src={Config.API_URL + 'collections/image/' + collection.image} alt={collection.name} />
                        </Link>
                      </div>
                    );
                  })}
                </div>
            </section> 
        </div>
    );
  }
}

export default Collections;
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faImages, faMonument, faFileAlt, faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { BreadcrumbLink } from '../components'
import { Config } from '../config'

const Documents = () => {
    const description = 'Esta sección organiza el conjunto de materiales gráficos disponibles en Musiteca.mx. Aquí podrás consultar una gran variedad de archivos digitalizados, como fotografías, partituras, publicaciones y documentos relevantes para la historia de la música en México.';

    useEffect(() => {
        Config.updateMetaTitle('Documentos');
        Config.updateMetaDescription(description);
    },[]);
  
    return (
        <div className="container">
            <section id="documents">
                <div className="breadcrumb">
                    <BreadcrumbLink path="" text="Inicio" />
                    <h1>Documentos</h1>
                </div>
                <div className="mb-2">
                    <blockquote>{description}</blockquote>
                </div>
                <div className="row mt-5 mb-5 d-flex justify-content-center text-center">
                    <div className="col-6 col-md-4 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/documentos/iconografias`}>
                            <FontAwesomeIcon icon={faImages} size="6x" />
                            <h2>Iconografías</h2>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/documentos/partituras`}>
                            <FontAwesomeIcon icon={faMusic} size="6x" />
                            <h2>Partituras</h2>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/documentos/publicaciones`}>
                            <FontAwesomeIcon icon={faFileAlt} size="6x" />
                            <h2>Publicaciones</h2>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/documentos/archivo-historico`}>
                            <FontAwesomeIcon icon={faMonument} size="6x" />
                            <h2>Archivo histórico</h2>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-lg-2 pt-5 d-flex justify-content-center align-items-center">
                        <Link to={`/libros`}>
                            <FontAwesomeIcon icon={faBookOpen} size="6x" />
                            <h2>Libros y audiolibros</h2>
                        </Link>
                    </div>
                </div>
            </section> 
        </div>
    );
}

export default Documents;
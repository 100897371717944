import { Config } from '../../config'
import useSharedFormState from '../../store';

const ChildrenDocumentCard = (props) => {
    const { setImage, setIsImageOpen, setFile, setIsFileOpen, setPageNumber, setMultimediaDetails, setAreMediaDetailsOpen } = useSharedFormState();

    const showFile = () => {
        var fileExt = props.file.split('.').pop();
        if(fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif') {
            setImage({
                title: props.title,
                file: props.file
            });
            setIsImageOpen(true);
        } else {
            setFile({
                id: props.id,
                title: props.title,
                file: props.file
            });
            setIsFileOpen(true);
            setPageNumber(1);
        }
    }

    const showDocumentDetail = (id) => {
        fetch(Config.API_URL + 'multimedia/detail/' + id)
          .then(res => res.json())
          .then(
              (data) => {
                    setMultimediaDetails(data);
                    setAreMediaDetailsOpen(true);
              },
              (error) => {
                setAreMediaDetailsOpen(false);
              }
          )
      }

    return (
        <div className={`card card-section card-children ${props.classes}`} style={{ backgroundImage: `url(`+(props.imagepath?`${Config.API_URL}${props.imagepath}/image/`:Config.ASSETS_URL)+`${props.image})` }}>
            <button onClick={showFile} className="card-body">
                <h5 className="card-title">{props.title}</h5>
            </button>
            <button className="btn btn-sm btn-link" onClick={() => showDocumentDetail(props.id)} style={{position:'absolute', bottom: -30}}>Ver créditos</button>
        </div>
    )
}

export default ChildrenDocumentCard;
import React from 'react'
import Modal from 'react-modal';
import { Config } from '../../config'
import useSharedFormState from '../../store';

const ImageModal = () => {
    const { imageIsOpen, setIsImageOpen, image } = useSharedFormState();

    function afterOpenModal() { }

    function closeModal() {
      setIsImageOpen(false);
    }

    const customStyles = {
      content: {
        top: 'auto',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, 5%)',
        border: 'none',
        borderRadius: '0',
        width: '65%',
        maxHeight: '90vh',
        textAlign: 'center',
      },
    };
    
    return (
        <Modal isOpen={imageIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Detalle de imagen">
            <button className="btn btn-link btn-close" onClick={closeModal}></button>
            
            <h5><span>{image.title}</span></h5>
            
            {image.file?(
              <img src={`${Config.ASSETS_URL}${image.file}`} className="" alt={image.title} />
            ):false}
        </Modal>
    )
}

export default ImageModal;
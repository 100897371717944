export const Config = {
  API_URL: 'https://musiteca.mx/api/',
  ASSETS_URL: 'https://musiteca.mx/media/',
  VIDEOS_URL: 'https://streamer.fonotecanacional.gob.mx',
  DOCUMENTS_URL: 'https://musiteca.mx/media/',

  isVideo: function(file) {
    var fileExt = file.split('.').pop();
    if(fileExt === 'mp4' || fileExt === 'm3u8') {
      return true;
    } else {
      return false;
    }
  },

  isAudio: function(file) {
    var fileExt = file.split('.').pop();
    if(fileExt === 'mp3') {
      return true;
    } else {
      return false;
    }
  },

  isImage: function(file) {
    var fileExt = file.split('.').pop();
    if(fileExt === 'jpeg' || fileExt === 'jpg' || fileExt === 'png') {
      return true;
    } else {
      return false;
    }
  },

  formatDate: function(date) {
    var year  = date.substring(0,4);
    var month = date.substring(5,7);
    var day   = date.substring(8,10);
    
    return day+'/'+month+'/'+year;
  },
  formatDateHour: function(date) {
    var year  = date.substring(0,4);
    var month = date.substring(5,7);
    var day   = date.substring(8,10);
    var hour  = date.substring(11,16);
    
    return day+'/'+month+'/'+year+' '+hour;
  },
  formatLiveDate: function(live) {
    var year  = live.date.substring(0,4);
    var month = live.date.substring(5,7);
    var day   = live.date.substring(8,10);

    switch(month){
      case '01':
        month = "enero"; break;
      case '02':
        month = "febrero"; break;
      case '03':
        month = "marzo"; break;
      case '04':
        month = "abril"; break;
      case '05':
        month = "mayo"; break;
      case '06':
        month = "junio"; break;
      case '07':
        month = "julio"; break;
      case '08':
        month = "agosto"; break;
      case '09':
        month = "septiembre"; break;
      case '10':
        month = "octubre"; break;
      case '11':
        month = "noviembre"; break;
      case '12':
        month = "diciembre"; break;
    }

    return day+' de '+month+' del '+year;
  },
  getCurrentDate: function(separator='-'){

    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date} ${hour<10?`0${hour}`:`${hour}`}:${minutes<10?`0${minutes}`:`${minutes}`}:${seconds<10?`0${seconds}`:`${seconds}`}`;
  },

  getRegex: function(keyword){
      var newKeyword = keyword.replaceAll("á","a");
      newKeyword = newKeyword.replaceAll("a","[áa]");
      newKeyword = newKeyword.replaceAll("é","e");
      newKeyword = newKeyword.replaceAll("e","[ée]");
      newKeyword = newKeyword.replaceAll("í","i");
      newKeyword = newKeyword.replaceAll("i","[íi]");
      newKeyword = newKeyword.replaceAll("ó","o");
      newKeyword = newKeyword.replaceAll("o","[óo]");
      newKeyword = newKeyword.replaceAll("ú","u");
      newKeyword = newKeyword.replaceAll("u","[úu]");
      
      return new RegExp('('+newKeyword+')' , 'igu');
  },

  updateMetaTitle: function(title) {
    document.title = 'Musiteca'+(title?' | ' + title:'');
    document.querySelector('meta[property="og:title"]').setAttribute("content", "Musiteca"+(title?" - " + title:""));
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", "Musiteca"+(title?" - " + title:""));

    document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);
    document.querySelector('meta[property="twitter:url"]').setAttribute("content", window.location.href);
  },
  updateMetaDescription: function(description) {
    document.querySelector('meta[name="description"]').setAttribute("content", description);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", description);
  },
  updateMetaImage: function(url) {
    document.querySelector('meta[property="og:image"]').setAttribute("content", url);
  }
}

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useSharedFormState from '../store';
import { AddAudioButton, BreadcrumbLink, MediaDetail, PlayButton, SeeDocumentButton, ShareButton } from '../components';
import { Config } from '../config';

const Multimedia = () => {
    const location = useLocation();

    const { setMultimediaDetails } = useSharedFormState();

    let { id } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [multimedia, setMultimedia] = useState(null);

    useEffect(() => {
        if(!id) return;

        fetch(Config.API_URL + 'multimedia/detail/'+id)
            .then(res => res.json())
            .then(
                (data) => {
                    Config.updateMetaTitle('Multimedia - '+data.title+(data.movement?' - '+data.movement:''));
                    Config.updateMetaDescription(data.notes?data.notes:(data.album?data.album:(data.source?data.source:data.author)));

                    setIsLoaded(true);
                    setMultimedia(data);
                    setMultimediaDetails(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [location,id,setMultimediaDetails]);

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded || !multimedia) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {


        return (
            <div className="container mb-5">
                <section>
                    <div className="breadcrumb">
                        <BreadcrumbLink path="" text="Inicio" />
                        <BreadcrumbLink path="" text="Multimedia" />
                        <h1>{multimedia.title}</h1>
                    </div>
                </section> 
                <section id="multimedia-detail">
                    <div className="mb-4 text-end">
                        {Config.isVideo(multimedia.file) || Config.isAudio(multimedia.file)?
                            <PlayButton autoplay={true} id={multimedia.id} title={multimedia.title} file={multimedia.file} author={multimedia.author} movement={multimedia.movement} />:
                            <SeeDocumentButton id={multimedia.id} title={multimedia.title} file={multimedia.file} author={multimedia.author} />
                        }
                        {Config.isAudio(multimedia.file)?
                            <AddAudioButton id={multimedia.id} title={multimedia.title} file={multimedia.file} author={multimedia.author} />:
                            null
                        }
                        <ShareButton />
                    </div>

                    <MediaDetail />
                </section>
            </div>
        );

    }
}

export default Multimedia;
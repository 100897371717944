import React, { Component } from 'react'
import { DetailButton, PlayButton, AddAudioButton, TotalCountItems } from '../../components'
import { Config } from '../../config'

export default class MediaTable extends Component {
    
    render() {
        if(this.props.items.length) {
            return (
                <div className="table-responsive">
                    <table className="table table-multimedia table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                {this.props.mexican || this.props.indigenous || this.props.fromHome?<th>Título</th>:<th>Obra / Título</th>}
                                {!this.props.items[0].file || this.props.mexican || this.props.indigenous || this.props.fromHome || this.props.video?null:<th>Movimiento</th>}
                                {this.props.fromHome?<th>Tipo</th>:null}
                                {this.props.indigenous || this.props.fromHome?null:<th>Compositor / Autor</th>}
                                <th>Intérpretes / Participantes</th>
                                {this.props.items[0].file && (this.props.essential || this.props.microsite || this.props.author || this.props.search)?<th>Solista</th>:null}
                                {this.props.items[0].file && (this.props.essential || this.props.microsite || this.props.author || this.props.search)?<th>Director</th>:null}
                                {this.props.indigenous?<th>Lugar o entidad de grabación</th>:null}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.items.map((item,index) => {
                                if(!item.file && !item.link) return null;
                                
                                var title = item.title?item.title:'<em>N/A</em>';
                                var movement = item.movement?item.movement:'<em>N/A</em>';
                                var album = item.album?item.album:'<em>N/A</em>';
                                var author = item.author?item.author:'<em>N/A</em>';
                                var performer = item.performer?item.performer:'<em>N/A</em>';
                                var soloist = item.soloist?item.soloist:'<em>N/A</em>';
                                var director = item.director?item.director:'<em>N/A</em>';
                                var recording_place = item.recording_place?item.recording_place:'<em>N/A</em>';
                                if(this.props.keyword) {
                                    var searchRegex = Config.getRegex(this.props.keyword);
                                    if(title) title = title.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(movement) movement = movement.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(album) album = album.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(author) author = author.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(performer) performer = performer.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(soloist) soloist = soloist.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(director) director = director.replace(searchRegex, '<span class="highlight">$1</span>');
                                    if(recording_place) recording_place = recording_place.replace(searchRegex, '<span class="highlight">$1</span>');
                                }

                                return (
                                    <tr key={`row-item-${index}`} filter={item.filter}>
                                        {item.file?
                                            <td className="multimedia-actions">
                                                <PlayButton id={item.id} title={item.title} file={item.file} author={item.author?item.author:item.performer} movement={item.movement} />
                                                {Config.isVideo(item.file)?'':<AddAudioButton id={item.id} title={item.title} file={item.file} author={item.author?item.author:item.performer} />}
                                            </td>:
                                            <td className="multimedia-actions multimedia-link">
                                                <iframe style={{borderRadius: 12+'px'}} src={item.link+"?utm_source=generator&theme=0"} title={item.id} width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                                            </td>
                                        }
                                        <td dangerouslySetInnerHTML={{__html:title}}></td>
                                        {!item.file || this.props.mexican || this.props.indigenous || this.props.fromHome || this.props.video?null:<td dangerouslySetInnerHTML={{__html:movement}}></td>}
                                        {this.props.fromHome?<td dangerouslySetInnerHTML={{__html:album}}></td>:null}
                                        {this.props.indigenous || this.props.fromHome?null:<td dangerouslySetInnerHTML={{__html:author}}></td>}
                                        <td dangerouslySetInnerHTML={{__html:performer}}></td>
                                        {item.file && (this.props.essential || this.props.microsite || this.props.author || this.props.search)?<td dangerouslySetInnerHTML={{__html:soloist}}></td>:null}
                                        {item.file && (this.props.essential || this.props.microsite || this.props.author || this.props.search)?<td dangerouslySetInnerHTML={{__html:director}}></td>:null}
                                        {this.props.indigenous?<td dangerouslySetInnerHTML={{__html:recording_place}}></td>:null}
                                        <td><DetailButton keyword={this.props.keyword} id={item.id}/></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <TotalCountItems items={this.props.items}/>
                </div>
            )
        } else {
            return (<p className="text-center h4 mt-3">Por el momento, no hay información.</p>)
        }
    }
}
import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { BreadcrumbLink } from '../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faImages, faPlay, faMonument, faVideo, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { MediaTable, DocumentsTable, ImageGrid } from '../components'
import { Config } from '../config';

const Novelties = () => {
    const location = useLocation();

    let { url } = useParams();
    
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [multimedia, setMultimedia] = useState([]);
    const [audios, setAudios] = useState([]);
    const [icono, setIcono] = useState([]);
    const [musicSheets, setMusicSheets] = useState([]);
    const [publications, setPublications] = useState([]);
    const [videos, setVideos] = useState([]);
    const [historicArchives, setHistoricArchives] = useState([]);

    const description = 'Esta sección se encuentra en constante movimiento y tiene como objetivo mostrar las actualizaciones periódicas de los contenidos de Musiteca.mx.';

    useEffect(() => {
        Config.updateMetaTitle('Novedades');
        Config.updateMetaDescription(description);
    }, []);

    useEffect(() => {
        setAudios(null);
        setIcono(null);
        setMusicSheets(null);
        setPublications(null);
        setVideos(null);
        setHistoricArchives(null);
        
        if(multimedia.length || Object.entries(multimedia).length) {
            initData(url, multimedia);
        } else {
            fetch(Config.API_URL + 'novelties')
                .then(res => res.json())
                .then(
                    (data) => {
                        initData(url, data);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }
    }, [location,url]);

    const initData = (url, data) => {
        setIsLoaded(true);
        if(url == 'audios') {
            Config.updateMetaTitle('Novedades - Audios');
            setAudios(data.audios);
        } else if(url == 'iconografias') {
            Config.updateMetaTitle('Novedades - Iconografías');
            setIcono(data.icono);
        } else if(url == 'partituras') {
            Config.updateMetaTitle('Novedades - Partituras');
            setMusicSheets(data.music_sheets);
        } else if(url == 'publicaciones') {
            Config.updateMetaTitle('Novedades - Publicaciones');
            setPublications(data.publications);
        } else if(url == 'videos') {
            Config.updateMetaTitle('Novedades - Videos');
            setVideos(data.videos);
        } else if(url == 'archivo-historico') {
            Config.updateMetaTitle('Novedades - Archivos Históricos');
            setHistoricArchives(data.historic_archives);
        } else {
            setMultimedia(data);
        }
    }

    let currentContent = <p className="text-center h4 mt-3">Por el momento, no hay novedades.</p>;

    if(audios) {
        currentContent = <MediaTable items={audios} novelty={true}></MediaTable>;
    } else if(icono) {
        currentContent = <ImageGrid multimedia={icono} />
    } else if(musicSheets) {
        currentContent = <DocumentsTable items={musicSheets} musicSheets={true}/>
    } else if(publications){
        currentContent = <DocumentsTable items={publications} publications={true}/>
    } else if(videos) {
        currentContent = <MediaTable items={videos} video={true}/>
    } else if(historicArchives) {
        currentContent = <DocumentsTable items={historicArchives} historicArchives={true}/>
    } else {
        currentContent = <div className="novelty-div">
                            {multimedia.audios?
                                <div className="novelty-box">
                                    <Link to="/novedades/audios" className={"btn"} type="button">
                                        {/* <div class="novelty-content"> */}
                                            <FontAwesomeIcon icon={faPlay} size="2x" /><br/>
                                            Audios
                                            <div className="novelty-length">
                                                {multimedia.audios.length}
                                            </div>
                                        {/* </div> */}
                                    </Link>
                                </div>
                            :null}
                            {multimedia.icono?
                                <div className="novelty-box">
                                    <Link to="/novedades/iconografias" className={"btn"} type="button">
                                        {/* <div class="novelty-content"> */}
                                            <FontAwesomeIcon icon={faImages} size="2x" /><br/>
                                            Iconografías
                                            <div className="novelty-length">
                                                {multimedia.icono.length}
                                            </div>
                                        {/* </div> */}
                                    </Link>
                                </div>
                            :null}
                            {multimedia.music_sheets?
                                <div className="novelty-box">
                                    <Link to="/novedades/partituras" className={"btn"} type="button">
                                        {/* <div class="novelty-content"> */}
                                            <FontAwesomeIcon icon={faMusic} size="2x" /><br/>
                                            Partituras
                                            <div className="novelty-length">
                                                {multimedia.music_sheets.length}
                                            </div>
                                        {/* </div> */}
                                    </Link>
                                </div>
                            :null}
                            {multimedia.publications?
                                <div className="novelty-box">
                                    <Link to="/novedades/publicaciones" className={"btn"} type="button">
                                        {/* <div class="novelty-content"> */}
                                            <FontAwesomeIcon icon={faFileAlt} size="2x" /><br/>
                                            Publicaciones
                                            <div className="novelty-length">
                                                {multimedia.publications.length}
                                            </div>
                                        {/* </div> */}
                                    </Link>
                                </div>
                            :null}
                            {multimedia.videos?
                                <div className="novelty-box">
                                    <Link to="/novedades/videos" className={"btn"} type="button">
                                        {/* <div class="novelty-content"> */}
                                            <FontAwesomeIcon icon={faVideo} size="2x" /><br/>
                                            Videos
                                            <div className="novelty-length">
                                                {multimedia.videos.length}
                                            </div>
                                        {/* </div>   */}
                                    </Link>
                                </div>
                            :null}
                            {multimedia.historic_archives?
                                <div className="novelty-box">
                                    <Link to="/novedades/archivo-historico" className={"btn"} type="button">
                                        {/* <div class="novelty-content"> */}
                                            <FontAwesomeIcon icon={faMonument} size="2x" /><br/>
                                            Archivos Históricos
                                            <div className="novelty-length">
                                                {multimedia.historic_archives.length}
                                            </div>
                                        {/* </div>         */}
                                    </Link>
                                </div>
                            :null}
                            {/* <MultimediaTabs keyword={false} multimedia={multimedia} novelty={true} noElements={() => {return (<p className="text-center h4 mt-3">Por el momento, no hay novedades.</p>);}} /> */}
                        </div>
    }

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        return (
            <div className="container">
                    <section>
                        <div className="breadcrumb">
                            <BreadcrumbLink path="" text="Inicio" />
                            {url?<BreadcrumbLink path="novedades" text="Novedades" />:<h1>Novedades</h1>}
                            {audios?<h1>Audios</h1>:
                                (icono?<h1>Iconografías</h1>:
                                    (musicSheets?<h1>Partituras</h1>:
                                        (publications?<h1>Publicaciones</h1>:
                                            (videos?<h1>Videos</h1>:
                                                (historicArchives?<h1>Archivo histórico</h1>:
                                                null)
                                            )
                                        )
                                    )
                                )
                            }
                        </div>
                        {!url?
                            <div className="mb-2">
                                <blockquote>{description}</blockquote>
                            </div>:
                        null}
                    </section> 
                    <section id="novelties">
                        {currentContent}
                    </section>
            </div>
        );

    }
}

export default Novelties;
import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { BreadcrumbLink, SectionCard } from '../components'
import { Config } from '../config'

const DocumentsBios = () => {
  const location = useLocation();

  let { url } = useParams();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [documentBios, setDocumentBios] = useState([]);
  const [currentDocumentBio, setCurrentDocumentBio] = useState(null);

  useEffect(() => {
    Config.updateMetaTitle('Documentos - Biografías');
    Config.updateMetaDescription('Aquí podrás consultar biografías relevantes para la historia de la música en México.');
  },[]);

  useEffect(() => {
    if(url) {
      fetch(Config.API_URL + 'documents/biography/' + url)
          .then(res => res.json())
          .then(
              (data) => {
                  window.scrollTo(0, 0);
                  setCurrentDocumentBio(data);
                  setIsLoaded(true);
              },
              (error) => {
                  setError(error);
                  setIsLoaded(true);
              }
          );
    } else {
      fetch(Config.API_URL + 'documents/biographies?p=0&l=50')
        .then(res => res.json())
        .then(
          (data) => {
            setIsLoaded(true);
            setDocumentBios(data);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
      setCurrentDocumentBio(null);
    }
  }, [location,url]);

  if (error) {
    return <div className="text-center">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="text-center mt-5">Cargando...</div>;
  } else {
    let currentContent = null;

    if(currentDocumentBio) {
      var bio = `<img src="${Config.ASSETS_URL}${currentDocumentBio.file}" style="float: left; margin-right: 20px;" alt="${url}" />`+currentDocumentBio.bio;
      currentContent =  <div>
                          <h2 className="mb-3">{url}</h2>
                          <div dangerouslySetInnerHTML={{__html:bio}} style={{textAlign: "justify"}}></div>
                        </div>;
    } else {
      currentContent = (
        <div className="row">
          {documentBios.map((bio,index) => {
            return (
              <div className="col-12 col-sm-6 col-lg-4" key={`bio-${index}`}>
                <SectionCard section="" url={`documentos/biografias/${bio.title}`} name={bio.title} description="" image={bio.file} imagepath="" />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        <div className="container">
            <section id="documents-biographies">
                <div className="breadcrumb">
                  <BreadcrumbLink path="" text="Inicio" />
                  <BreadcrumbLink path="documentos" text="Documentos" />
                  {currentDocumentBio? <BreadcrumbLink path="documentos/biografias" text="Biografías" />:<h1>Biografías</h1>}
                </div>
                <div className="row">
                    <div className="col-12 col-lg-2">
                      <div className="list-group d-none d-lg-block">
                        <Link key={`documents-option-bio`} to={`/documentos/biografias`} className={"list-group-item list-group-item-action active"}>
                            Biografías
                        </Link>
                        <Link key={`documents-option-iconography`} to={`/documentos/iconografias`} className={"list-group-item list-group-item-action"}>
                            Iconografías
                        </Link>
                        <Link key={`documents-option-musicsheet`} to={`/documentos/partituras`} className={"list-group-item list-group-item-action"}>
                            Partituras
                        </Link>
                        <Link key={`documents-option-publication`} to={`/documentos/publicaciones`} className={"list-group-item list-group-item-action"}>
                            Publicaciones
                        </Link>
                        <Link key={`documents-option-historic-archive`} to={`/documentos/archivo-historico`} className={"list-group-item list-group-item-action"}>
                            Archivo histórico
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 col-lg-10">
                      {currentContent}
                    </div>
                </div>
            </section>
        </div>
      </div>
    );
  }
}
export default DocumentsBios;
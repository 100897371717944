import React from 'react'

export const TotalCountItems = (props) => {
    if(typeof props.items === "undefined") {
        return null
    } else {
        return (
            <p>Mostrando {props.items.length} registro{props.items.length===1?"":"s"}</p>
        )
    }  
}

export default TotalCountItems;
import React, { useEffect, useState, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loading, DetailButton, PlayButton, AddAudioButton, TotalCountItems } from '../../components'
import { Config } from '../../config'

const MediaTablePaged = (props) => {
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(10);
    const [fetching, setFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);
    const [urlChange, setUrlChange] = useState(0); // Contador para resetear imágenes cuando el url cambia

    const isFirstRender = useRef(true);

    // useEffect(() => {
    //     loadData();
    // },[]);

    useEffect(() => {
        loadData();
    },[urlChange]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }

        setItems([]);
        setPage(0);
        setFetching(false);
        setHasMore(true);
        setUrlChange(urlChange + 1);
    },[props.url,props.params]);

    const loadData = () => {
        if(fetching) return;
    
        setFetching(true);
        
        fetch(Config.API_URL + props.url + '?p='+page+'&l='+length+(props.params?'&'+props.params:''))
            .then(res => res.json())
            .then(
                (data) => {
                    setItems([...items, ...data]);
                    setPage(page + 1);

                    if(data.length < length) setHasMore(false);

                    // setTimeout(()=>{setFetching(false)},200);
                    setFetching(false);
                },
                (error) => {
                    setFetching(false);
                    setError(error);
                }
            );
    };
    
    return (
        <InfiniteScroll
            dataLength={items.length}
            next={loadData}
            hasMore={hasMore}
            loader={<Loading />}
        >
            <div className="table-responsive">
                <table className="table table-multimedia table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            {props.mexican || props.indigenous || props.fromHome?<th>Título</th>:<th>Obra / Título</th>}
                            {props.mexican || props.indigenous || props.fromHome || props.video?null:<th>Movimiento</th>}
                            {props.fromHome?<th>Tipo</th>:null}
                            {props.indigenous || props.fromHome?null:<th>Compositor / Autor</th>}
                            <th>Intérpretes / Participantes</th>
                            {props.essential || props.microsite || props.author || props.search?<th>Solista</th>:null}
                            {props.essential || props.microsite || props.author || props.search?<th>Director</th>:null}
                            {props.indigenous?<th>Lugar o entidad de grabación</th>:null}
                            {props.source?<th>Procedencia</th>:null}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item,index) => {
                            if(!item.file && !item.link) return null;
                            
                            var title = item.title?item.title:'<em>N/A</em>';
                            var movement = item.movement?item.movement:'<em>N/A</em>';
                            var album = item.album?item.album:'<em>N/A</em>';
                            var author = item.author?item.author:'<em>N/A</em>';
                            var performer = item.performer?item.performer:'<em>N/A</em>';
                            var soloist = item.soloist?item.soloist:'<em>N/A</em>';
                            var director = item.director?item.director:'<em>N/A</em>';
                            var recording_place = item.recording_place?item.recording_place:'<em>N/A</em>';
                            var source = item.source?item.source:'<em>N/A</em>';
                            if(props.keyword) {
                                var searchRegex = Config.getRegex(props.keyword);
                                if(title) title = title.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(movement) movement = movement.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(album) album = album.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(author) author = author.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(performer) performer = performer.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(soloist) soloist = soloist.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(director) director = director.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(recording_place) recording_place = recording_place.replace(searchRegex, '<span class="highlight">$1</span>');
                                if(source) source = source.replace(searchRegex, '<span class="highlight">$1</span>');
                            }

                            return (
                                <tr key={`row-item-${index}`} filter={item.filter}>
                                    {item.file?
                                        <td className="multimedia-actions">
                                            <PlayButton id={item.id} title={item.title} file={item.file} author={item.author?item.author:item.performer} movement={item.movement} />
                                            {Config.isVideo(item.file)?'':<AddAudioButton id={item.id} title={item.title} file={item.file} author={item.author?item.author:item.performer} />}
                                        </td>:
                                        <td className="multimedia-actions">
                                            <iframe style={{borderRadius: 12+'px'}} src={item.link+"?utm_source=generator&theme=0"} title={item.id} width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                                        </td>
                                    }
                                    <td dangerouslySetInnerHTML={{__html:title}}></td>
                                    {props.mexican || props.indigenous || props.fromHome || props.video?null:<td dangerouslySetInnerHTML={{__html:movement}}></td>}
                                    {props.fromHome?<td dangerouslySetInnerHTML={{__html:album}}></td>:null}
                                    {props.indigenous || props.fromHome?null:<td dangerouslySetInnerHTML={{__html:author}}></td>}
                                    <td dangerouslySetInnerHTML={{__html:performer}}></td>
                                    {props.essential || props.microsite || props.author || props.search?<td dangerouslySetInnerHTML={{__html:soloist}}></td>:null}
                                    {props.essential || props.microsite || props.author || props.search?<td dangerouslySetInnerHTML={{__html:director}}></td>:null}
                                    {props.indigenous?<td dangerouslySetInnerHTML={{__html:recording_place}}></td>:null}
                                    {props.source?<td dangerouslySetInnerHTML={{__html:source}}></td>:null}
                                    <td><DetailButton keyword={props.keyword} id={item.id} /></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <TotalCountItems items={items}/>
            </div>
        </InfiniteScroll>
    );
}

export default MediaTablePaged;
import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { BreadcrumbLink, SectionCard, MediaTable, DocumentsTable, ImageGrid } from '../components'
import { Config } from '../config'

const Iberoamerica = () => {
    const location = useLocation();

    let { url } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currentCountry, setCurrentCountry] = useState(null);

    useEffect(() => {
      Config.updateMetaTitle('Iberoamérica');
      Config.updateMetaDescription('Esta sección está dedicada a los países miembros del Programa Ibermemoria Sonora y Audiovisual.');

      fetch(Config.API_URL + 'iberoamerica')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setCountries(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          );
    }, []);

    useEffect(() => {
      if(url) {
        fetch(Config.API_URL + 'iberoamerica/detail/' + url)
            .then(res => res.json())
            .then(
                (data) => {
                    setCurrentCountry(data);
                    Config.updateMetaTitle('Iberoamérica - '+data.name);
                },
                (error) => {
                    setError(error);
                }
            );
      } else {
        setCurrentCountry(null);
      }
    }, [location,url]);

    let main = <div className="row">
                  {countries.map((country,index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-3" key={`country-${index}`}>
                        <SectionCard classes="card-essential" section="iberoamerica-suena" url={`${country.url}`} name={country.name} image={country.image} imagepath="iberoamerica" />
                      </div>
                    );
                  })}
                </div>

    let currentContent = main;

    if(currentCountry) {
      currentContent =  <div className="row">
                          <div className="col-12 col-lg-2">
                              <div className="list-group d-none d-lg-block">
                                {countries.map((country,indexm) => {
                                  return (
                                    <Link key={`country-option-${indexm}`} to={`/iberoamerica-suena/${country.url}`} className={"list-group-item list-group-item-action"+(currentCountry && currentCountry.name === country.name?" active":"")}>
                                      {country.name}
                                    </Link>
                                  );
                                })}
                              </div>
                          </div>

                          <div className="col-12 col-lg-10">
                            <div className="text-center">
                              <img src={`${Config.API_URL}iberoamerica/image/${currentCountry.image}`} className="img-fluid rounded-start" alt="..." height="30" />
                            </div>
                            <nav className="mt-4">
                              <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                {currentCountry.audios?
                                  currentCountry.audios.map((category, index) => 
                                  <button className={index===0?"nav-link active":"nav-link"} id={"nav-audios-tab-"+index} data-bs-toggle="tab" data-bs-target={"#nav-audios-"+index} type="button" role="tab" aria-controls={"nav-audios-"+index} aria-selected="true">{category.name}</button>
                                  ):null
                                }
                                {currentCountry.icono && currentCountry.icono.length?<button className="nav-link" id="nav-icono-tab" data-bs-toggle="tab" data-bs-target="#nav-icono" type="button" role="tab" aria-controls="nav-icono" aria-selected="false">Iconografías</button>:null}
                                {currentCountry.music_sheets && currentCountry.music_sheets.length?<button className="nav-link" id="nav-music-sheets-tab" data-bs-toggle="tab" data-bs-target="#nav-music-sheets" type="button" role="tab" aria-controls="nav-music-sheets" aria-selected="false">Partituras</button>:null}
                                {currentCountry.publications && currentCountry.publications.length?<button className="nav-link" id="nav-publications-tab" data-bs-toggle="tab" data-bs-target="#nav-publications" type="button" role="tab" aria-controls="nav-publications" aria-selected="false">Publicaciones</button>:null}
                                {currentCountry.videos && currentCountry.videos.length?<button className="nav-link" id="nav-videos-tab" data-bs-toggle="tab" data-bs-target="#nav-videos" type="button" role="tab" aria-controls="nav-videos" aria-selected="false">Videos</button>:null}
                                {currentCountry.historic_archives && currentCountry.historic_archives.length?<button className="nav-link" id="nav-historic-archives-tab" data-bs-toggle="tab" data-bs-target="#nav-historic-archives" type="button" role="tab" aria-controls="nav-historic-archives" aria-selected="false">Archivos Históricos</button>:null}
                              </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                              {currentCountry.audios?
                                  currentCountry.audios.map((category, index) => 
                                  <div className={index===0?"tab-pane fade show active":"tab-pane"} id={"nav-audios-"+index} role="tabpanel" aria-labelledby={"nav-audios-tab-"+index}>
                                    {/* TODO MEBJ: colocar if de Voz */}
                                    {console.log(category.name)}
                                    <MediaTable items={category.audios} iberoamerica={true}></MediaTable>
                                  </div>
                                  ):null
                              }
                              {currentCountry.icono?
                              <div className="tab-pane fade" id="nav-icono" role="tabpanel" aria-labelledby="nav-icono-tab">
                                <ImageGrid multimedia={currentCountry.icono} />
                              </div>:null}
                              {currentCountry.music_sheets?
                              <div className="tab-pane fade" id="nav-music-sheets" role="tabpanel" aria-labelledby="nav-icono-tab">
                                <DocumentsTable items={currentCountry.music_sheets} iberoamerica={true}></DocumentsTable>
                              </div>:null}
                              {currentCountry.publications?
                              <div className="tab-pane fade" id="nav-publications" role="tabpanel" aria-labelledby="nav-icono-tab">
                                <DocumentsTable items={currentCountry.publications} iberoamerica={true}></DocumentsTable>
                              </div>:null}
                              {currentCountry.videos?
                              <div className="tab-pane fade" id="nav-videos" role="tabpanel" aria-labelledby="nav-videos-tab">
                                <MediaTable items={currentCountry.videos} iberoamerica={true}></MediaTable>
                              </div>:null}
                              {currentCountry.historic_archives?
                              <div className="tab-pane fade" id="nav-historic-archives" role="tabpanel" aria-labelledby="nav-historic-archives-tab">
                                <DocumentsTable items={currentCountry.historic_archives} iberoamerica={true}></DocumentsTable>
                              </div>:null}
                            </div>
                          </div>
                        </div>;
    }

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        return (
          <div className="container">
              <section id="countries">
                  <div className="breadcrumb">
                    <BreadcrumbLink path="" text="Inicio" />
                    {currentCountry && currentCountry.name?<BreadcrumbLink path="iberoamerica-suena" text="Iberoamérica suena" />:<h1>Iberoamérica suena</h1>}
                    {currentCountry && currentCountry.name?<h1>{currentCountry.name}</h1>:null}
                  </div>
                  {currentCountry && currentCountry.name?null:
                    <div className="mb-2">
                      <blockquote>
                        Esta sección está dedicada a los países miembros del Programa Ibermemoria Sonora y Audiovisual, a excepción 
                        de México que ya se encuentra representado en toda la plataforma. La curaduría actual se realizó en la base 
                        de datos de la Fonoteca Nacional de México, pero próximamente estos países enriquecerán 
                        la <a href="http://musiteca.mx">Musiteca.mx</a> con una selección de sus propios acervos.
                      </blockquote>
                    </div>
                  }
                  {currentContent}
              </section>
          </div>
        );
    }
}

export default Iberoamerica;
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbLink, DocumentsTablePaged } from '../components'
import { Config } from '../config'

const DocumentsPublications = () => {
    useEffect(() => {
        Config.updateMetaTitle('Documentos - Publicaciones');
        Config.updateMetaDescription('Aquí podrás consultar publicaciones relevantes para la historia de la música en México.');
    },[]);
    
    return (
        <div className="container">
            <section id="documents-publications">
                <div className="breadcrumb">
                    <BreadcrumbLink path="" text="Inicio" />
                    <BreadcrumbLink path="documentos" text="Documentos" />
                    <h1>Publicaciones</h1>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-2">
                        <div className="list-group d-none d-lg-block">
                        {/* <Link key={`documents-option-bio`} to={`/documentos/biografias`} className={"list-group-item list-group-item-action"}>
                            Biografías
                        </Link> */}
                        <Link key={`documents-option-iconography`} to={`/documentos/iconografias`} className={"list-group-item list-group-item-action"}>
                            Iconografías
                        </Link>
                        <Link key={`documents-option-musicsheet`} to={`/documentos/partituras`} className={"list-group-item list-group-item-action"}>
                            Partituras
                        </Link>
                        <Link key={`documents-option-publication`} to={`/documentos/publicaciones`} className={"list-group-item list-group-item-action active"}>
                            Publicaciones
                        </Link>
                        <Link key={`documents-option-historic-archive`} to={`/documentos/archivo-historico`} className={"list-group-item list-group-item-action"}>
                            Archivo histórico
                        </Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-10">
                        <DocumentsTablePaged url={'documents/publications'} publications={true}/>
                    </div>
                </div>
            </section>
        </div>
    );
    
}
export default DocumentsPublications;
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { BreadcrumbLink, MediaTable, SectionCard } from '../components'
import { Config } from '../config'

const Essential = () => {
    const location = useLocation();

    let { url1, url2, url3 } = useParams();

    // console.log('U1: ' + url1);
    // console.log('U2: ' + url2);
    // console.log('U3: ' + url3);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [categoryName1, setCategoryName1] = useState("");
    const [categoryName2, setCategoryName2] = useState("");
    const [categoryName3, setCategoryName3] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");
    const [categories, setCategories] = useState([]);
    const [audios, setAudios] = useState([]);
    const [filters, setFilters] = useState([]);
    const [filteredAudios, setFilteredAudios] = useState([]);
    const [filter, setFilter] = useState({});

    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
      Config.updateMetaTitle('Imprescindibles');
    },[]);

    useEffect(() => {
      setError(null);
      setIsLoaded(false);
      
      fetch(Config.API_URL + (url2?'categories/name/':'essentials/detail/') + url1)
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setCategoryName1(data.name);
                  if(!url2) {
                    // Config.updateMetaTitle(`Imprescindibles - ${data.name}`);
                    setCategoryDescription(data.description);
                    setCategories(data.categories);
                    setAudios(data.audios);
                    setFilteredAudios(data.audios);
                    setFilters(data.filters);
                  }
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          );
      if(url2) {
        fetch(Config.API_URL + (url3?'categories/name/':'essentials/detail/') + url2 + '?pa[]='+url1)
            .then(res => res.json())
            .then(
                (data) => {
                    setCategoryName2(data.name);
                    if(!url3) {
                      // Config.updateMetaTitle(`Imprescindibles - ${data.name}`);
                      setCategoryDescription(data.description);
                      setCategories(data.categories);
                      setAudios(data.audios);
                      setFilteredAudios(data.audios);
                      setFilters(data.filters);
                    }
                },
                (error) => {
                    setError(error);
                }
            );
      }
      if(url3) {
        fetch(Config.API_URL + 'essentials/detail/' + url3+ '?pa[]='+url1+'&pa[]='+url2)
            .then(res => res.json())
            .then(
                (data) => {
                    // Config.updateMetaTitle(`Imprescindibles - ${data.name}`);
                    setCategoryName3(data.name);
                    setCategoryDescription(data.description);
                    setCategories(data.categories);
                    setAudios(data.audios);
                    setFilteredAudios(data.audios);
                    setFilters(data.filters);
                },
                (error) => {
                    setError(error);
                }
            );
      }
    }, [location,url1,url2,url3]);

    useEffect(() => {
      if(!selectedValue) updateFilter(null);
      else updateFilter(selectedValue);
    },[selectedValue]);

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        let category1 = <h1>{categoryName1}</h1>;
        let category2 = null;
        let category3 = null;
        let metatitle = categoryName1;
        let metadescription = 'Imprescindibles';
        
        if(url2) {
          category1 = <BreadcrumbLink path={"imprescindibles/"+url1} text={` ${categoryName1}`} />;
          if(url3) {
            category2 = <BreadcrumbLink path={"imprescindibles/"+url1+"/"+url2} text={` ${categoryName2}`} />;
            category3 = <h1>{categoryName3}</h1>;
            metatitle += ' - ' + categoryName2 + ' - ' + categoryName3;
          } else {
            category2 = <h1>{categoryName2}</h1>;
            metatitle += ' - ' + categoryName2;
          }
        }

        let title = <div className="breadcrumb">
                      <BreadcrumbLink path="" text="Inicio" />
                      <BreadcrumbLink path="imprescindibles" text=" Imprescindibles" />
                      {category1}
                      {category2}
                      {category3}
                    </div>;

        let mainHeader = null;
        if(filter.id) {
          let mainBody = null;
          metadescription = filter.text;

          if(filter.description) {
            metadescription = filter.description;
            mainBody = <div className="card-body">
                        <p>{filter.description}</p>
                      </div>;
          }
          mainHeader =  <div className="card">
                          <h2 className="card-header d-none d-lg-block">{filter.text}</h2>
                          {mainBody}
                        </div>;
        }

        if(categoryDescription) {
          metadescription = categoryDescription;
        }
        Config.updateMetaTitle('Imprescindibles - '+metatitle);
        Config.updateMetaDescription(metadescription);

        let audiosTable = null;
        let labelTodos = null
        if(filteredAudios.length) {
          if( url1==="musica-de-las-regiones-de-mexico" ) {
            audiosTable = <MediaTable items={filteredAudios} mexican={true}></MediaTable>;
            labelTodos = 'Todas las regiones';
          } else if ( url1==="musica-de-las-culturas-indigenas" ) {
            audiosTable = <MediaTable items={filteredAudios} indigenous={true}></MediaTable>;
            labelTodos = 'Todos los pueblos';
          } else {
            audiosTable = <MediaTable items={filteredAudios} essential={true}></MediaTable>;
          }
        }

        if(filters.length) {
            let options = [{ value: '', label: labelTodos }];
            for(var i in filters) {
              options.push({ value: filters[i].id, label: filters[i].text});
            }
    
            const updateFilterSelect = (event) => {
              setSelectedValue(event.value);
            }
  
            return (
                <div className="container">
                    {title}
                    
                    {categoryDescription?<blockquote>{categoryDescription}</blockquote>:null}

                    <div className="row">
                        <div className="col-12 col-lg-2">
                            <div className="list-group d-none d-lg-block">
                                <button key={`essential-filter-all`} className={"list-group-item list-group-item-action"+(!filter.text || filter.id === null?" active":"")} onClick={() => updateFilter(null)}>Todos los pueblos</button>
                                {filters.map((element,index) => {
                                  return (
                                    <button key={`essential-filter-${index}`} className={"list-group-item list-group-item-action"+(filter.id === element.id?" active":"")} onClick={() => updateFilter(element.id)}>{element.text}</button>
                                  );
                                })}
                            </div>
                        </div>
                        <div className="col-12 essential-select">
                          <Select
                            defaultValue={options[0]}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="filters"
                            options={options}
                            onChange={updateFilterSelect}
                          />
                          {/* <div className="list-group">  
                            <select style={{width:'100%'}} onChange={updateFilterSelect}>
                              <option key={`essential-filter-all`} value="" className={"list-group-item list-group-item-action"+(!filter.text || filter.id === null?" active":"")}>Todo</option>
                              {filters.map((element,index) => {
                                return (
                                  <option key={`essential-filter-${index}`} value={element.id} className={"list-group-item list-group-item-action"+(filter.id === element.id?" active":"")}>{element.text}</option>
                                );
                              })}
                            </select>
                          </div> */}
                        </div>

                        <div className="col-12 col-lg-10">
                            {mainHeader}
                            
                            <section id="essentials-audios" className="mt-3">
                              {audiosTable}
                            </section>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container">
                    {title}
                    
                    {categoryDescription?<blockquote>{categoryDescription}</blockquote>:null}
                    
                    <section id="essentials">

                        <div className="row">
                          {categories.map((category,index) => {
                            let categoryUrl = url1+"/" + (url2?url2+"/":"") + (url3?url3+"/":"") + category.url;
                            // console.log(categoryUrl);

                            return (
                              <div className="col-12 col-md-4 col-lg-3" key={`essential-category-${index}`}>
                                <SectionCard classes="card-essential" section="imprescindibles" url={`${categoryUrl}`} name={category.name} description={category.description} image={category.image} imagepath="categories" />
                              </div>
                            );
                          })}   
                        </div>
                    </section>

                    <section id="essentials-audios">
                      {audiosTable}
                    </section>
                </div>
            );

        }
    }

    function updateFilter(filter_id) {
      setFilteredAudios(audios.filter( function (audio) {
        return filter_id?audio.filter === filter_id:true
      }));

      if(filter_id) setFilter(filters.find(element => element.id === filter_id));
      else setFilter({id: null,text:'',description:''});
    }
    // function updateFilterSelect(e) {
    //   setSelectedValue(e.target.value);
    // }
}
export default Essential;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { BreadcrumbLink } from '../components'
import { Config } from '../config'

const Authors = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [authors, setAuthors] = useState([]);
    const [currentLetter, setCurrentLetter] = useState('');

    const description = 'En este apartado presentamos el índice completo de autores y compositores de los registros de Musiteca.mx. Por autor, podrás acceder a los materiales de consulta disponibles.';

    useEffect(() => {
        Config.updateMetaTitle('Autores');
        Config.updateMetaDescription(description);

        fetch(Config.API_URL + 'authors')
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setAuthors(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, []);

    const resetAlphabet = () => {
        setCurrentLetter(false);
        window.scrollTo(0, 0);
    }

    if (error) {
      return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        return (
            <div className="container">
                <section id="authors">
                    <div className="breadcrumb">
                        <BreadcrumbLink path="" text="Inicio" />
                        <h1>Autores</h1>
                    </div>
                    <div className="mb-2">
                      <blockquote>{description}</blockquote>
                    </div>
                    {!currentLetter?<div className="row">
                        <div className="col-12 alphabet">
                            {Object.keys(authors).map((letter,index) => {
                                  return (
                                    <button className={`btn btn-${currentLetter === letter?'primary':'default'}`} key={`letter-${index}`} onClick={() => setCurrentLetter(letter)}>{letter}</button>
                                  );
                              })
                            }
                        </div>
                    </div>:<button className="btn btn-link btn-sm" onClick={() => resetAlphabet()}>Regresar al alfabeto</button>}
                    <div className="row mt-3">
                        <div className="col-12">
                            {Object.keys(authors).map((letter,index) => {
                                  return (!currentLetter || currentLetter === letter?
                                      <div>
                                          <h2 className="authors-letter">{letter}</h2>
                                          <ul className='authors-list'>
                                            {authors[letter].map((name,index) => {
                                                  return (
                                                    <li><Link key={`author-${letter}-${index}`} to={`/autores/${name}`} className="btn btn-default">{name}</Link></li>
                                                  );
                                              })
                                            }
                                          </ul>
                                      </div>:null
                                  );
                              })
                            }
                        </div>
                    </div>
                    {!currentLetter?"":<button className="btn btn-link btn-sm mb-5" onClick={() => resetAlphabet()}>Regresar al alfabeto</button>}
                </section>
            </div>
        );
    }
}
export default Authors;
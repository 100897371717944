import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Config } from '../../config'

export default class SectionCard extends Component {
    
    render() {
        let description = null;
        if(this.props.description) {
            description = <p className="card-text">{this.props.description}</p>;
        }

        return (
            <div className={`card card-section ${this.props.classes}`} style={{ backgroundImage: `url(`+(this.props.imagepath?`${Config.API_URL}${this.props.imagepath}/image/`:Config.ASSETS_URL)+`${this.props.image})` }}>
                <Link to={`/${this.props.section}/${this.props.url}`} className="card-body">
                    <h5 className="card-title">{this.props.name}</h5>
                    {description}
                </Link>
            </div>
        )
    }
}
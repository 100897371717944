import React from 'react'
import ReactGA from "react-ga4"
import { TotalCountItems } from '../../components'
import Gallery from 'react-grid-gallery';
import { Config } from '../../config'
import useSharedFormState from '../../store';

const ImageGrid = (props) => {
    const { setAreMediaDetailsOpen, setMultimediaDetails, setAltAuthorColumnTitle, setAltYearColumnTitle } = useSharedFormState();

    const showImageDetail = (id) => {
        fetch(Config.API_URL + 'multimedia/detail/' + id)
          .then(res => res.json())
          .then(
              (data) => {
                    setMultimediaDetails(data);
                    setAltAuthorColumnTitle(true);
                    setAltYearColumnTitle(false);
                    setAreMediaDetailsOpen(true);

                    ReactGA.event({
                        category: 'Multimedia',
                        action: 'Ver ficha',
                        value: parseInt(id),
                        label: data.internal_id
                    });
                    ReactGA.event({
                        category: 'Galería',
                        action: 'Ver ficha',
                        value: parseInt(id),
                        label: data.internal_id
                    });
              },
              (error) => {
                setAreMediaDetailsOpen(false);
              }
          )
    }

    const IMAGES = props.multimedia.map((iconography,index) => {
        
        var title = iconography.title;
        if(props.keyword){
            var searchRegex = new RegExp('('+props.keyword+')' , 'ig');
            if(title) title = title.replace(searchRegex, '<span class="highlight">$1</span>');
        }

        return (
            {
                src: `${Config.ASSETS_URL}${iconography.file}`,
                thumbnail: `${Config.ASSETS_URL}${iconography.file}`,
                // thumbnailWidth: 320,
                // thumbnailHeight: 174,
                // isSelected: true,
                alt: iconography.title,
                caption: (
                    <div>
                        <p className={'text-center'} dangerouslySetInnerHTML={{__html:title}}></p>
                        <button className='btn btn-sm btn-primary' onClick={() => showImageDetail(iconography.id)} style={{position: 'absolute',top: 5,left: 0}}>Ver créditos</button>
                    </div>
                ),
                thumbnailCaption: props.thumbnailCaption?iconography.title:'',
                customOverlay: (
                    <div className="custom-overlay__caption">
                      <div dangerouslySetInnerHTML={{__html:title}}></div>
                    </div>
                ),
            }
        );
    });

    if(props.multimedia.length) {
        return (
            <div>
                <Gallery images={IMAGES} backdropClosesModal={true} enableImageSelection={false} imageCountSeparator={' de '} margin={15} rowHeight={220} />
                <TotalCountItems items={props.items}/>
            </div>
        )
    } else {
        return (<p className="text-center h4 mt-3">Por el momento, no hay información.</p>)
    }
}

export default ImageGrid;
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faImages, faBookOpen, faPlay, faMonument, faVideo } from '@fortawesome/free-solid-svg-icons'
import { MediaTablePaged, MediaTable, DocumentsTablePaged, DocumentsTable, ImageGridPaged, ImageGrid } from '../../components'

export default class MultimediaTabs extends Component {
    
    render() {
        if(this.props.multimedia.audios || this.props.multimedia.historic_archives || this.props.multimedia.icono || this.props.multimedia.music_sheets || this.props.multimedia.publications || this.props.multimedia.videos) {
            let iconoActive = !this.props.multimedia.audios;
            let musicSheetsActive = !this.props.multimedia.audios && !this.props.multimedia.icono;
            let publicationsActive = !this.props.multimedia.audios && !this.props.multimedia.icono && !this.props.multimedia.music_sheets;
            let videosActive = !this.props.multimedia.audios && !this.props.multimedia.icono && !this.props.multimedia.music_sheets && !this.props.multimedia.publications;
            let historicActive = !this.props.multimedia.audios && !this.props.multimedia.icono && !this.props.multimedia.music_sheets && !this.props.multimedia.publications && !this.props.multimedia.videos;

            return (
                <div>
                    <nav className="mt-4">
                        <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                            {this.props.multimedia.audios?<button className={"nav-link active"} id="nav-audios-tab" data-bs-toggle="tab" data-bs-target="#nav-audios" type="button" role="tab" aria-controls="nav-audios" aria-selected="true"><FontAwesomeIcon icon={faPlay} size="2x" /><br/>Audios ({this.props.paged?this.props.multimedia.audios:this.props.multimedia.audios.length})</button>:null}
                            {this.props.multimedia.icono?<button className={"nav-link"+(iconoActive?" active":"")} id="nav-icono-tab" data-bs-toggle="tab" data-bs-target="#nav-icono" type="button" role="tab" aria-controls="nav-icono" aria-selected={iconoActive?"true":"false"}><FontAwesomeIcon icon={faImages} size="2x" /><br/>Iconografías ({this.props.paged?this.props.multimedia.icono:this.props.multimedia.icono.length})</button>:null}
                            {this.props.multimedia.music_sheets?<button className={"nav-link"+(musicSheetsActive?" active":"")} id="nav-music-sheets-tab" data-bs-toggle="tab" data-bs-target="#nav-music-sheets" type="button" role="tab" aria-controls="nav-music-sheets" aria-selected={musicSheetsActive?"true":"false"}><FontAwesomeIcon icon={faMusic} size="2x" /><br/>Partituras ({this.props.paged?this.props.multimedia.music_sheets:this.props.multimedia.music_sheets.length})</button>:null}
                            {this.props.multimedia.publications?<button className={"nav-link"+(publicationsActive?" active":"")} id="nav-publications-tab" data-bs-toggle="tab" data-bs-target="#nav-publications" type="button" role="tab" aria-controls="nav-publications" aria-selected={publicationsActive?"true":"false"}><FontAwesomeIcon icon={faBookOpen} size="2x" /><br/>Publicaciones ({this.props.paged?this.props.multimedia.publications:this.props.multimedia.publications.length})</button>:null}
                            {this.props.multimedia.videos?<button className={"nav-link"+(videosActive?" active":"")} id="nav-videos-tab" data-bs-toggle="tab" data-bs-target="#nav-videos" type="button" role="tab" aria-controls="nav-videos" aria-selected={videosActive?"true":"false"}><FontAwesomeIcon icon={faVideo} size="2x" /><br/>Videos ({this.props.paged?this.props.multimedia.videos:this.props.multimedia.videos.length})</button>:null}
                            {this.props.multimedia.historic_archives?<button className={"nav-link"+(historicActive?" active":"")} id="nav-historic-tab" data-bs-toggle="tab" data-bs-target="#nav-historic" type="button" role="tab" aria-controls="nav-historic" aria-selected={historicActive?"true":"false"}><FontAwesomeIcon icon={faMonument} size="2x" /><br/>Archivos Históricos ({this.props.paged?this.props.multimedia.historic_archives:this.props.multimedia.historic_archives.length})</button>:null}
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        {this.props.multimedia.audios?
                            <div className={"tab-pane fade show active"} id="nav-audios" role="tabpanel" aria-labelledby="nav-audios-tab">
                                {this.props.paged?
                                    <MediaTablePaged url={'search/audios'} params={this.props.params} keyword={this.props.keyword} collection={false} novelty={this.props.novelty} author={!this.props.novelty}></MediaTablePaged>:
                                    <MediaTable items={this.props.multimedia.audios} novelty={this.props.novelty} author={!this.props.novelty}></MediaTable>
                                }
                            </div>:null}
                        {this.props.multimedia.icono?
                            <div className={"tab-pane fade"+(iconoActive?" show active":"")} id="nav-icono" role="tabpanel" aria-labelledby="nav-icono-tab">
                                {this.props.paged?
                                    <ImageGridPaged url={'search/icono'} params={this.props.params} keyword={this.props.keyword} />:
                                    <ImageGrid keyword={this.props.keyword} multimedia={this.props.multimedia.icono} />
                                }
                            </div>:null}
                        {this.props.multimedia.music_sheets?
                            <div className={"tab-pane fade"+(musicSheetsActive?" show active":"")} id="nav-music-sheets" role="tabpanel" aria-labelledby="nav-music-sheets-tab">
                                {this.props.paged?
                                    <DocumentsTablePaged url={'search/musicsheets'} params={this.props.params} keyword={this.props.keyword} musicSheets={true}></DocumentsTablePaged>:
                                    <DocumentsTable keyword={this.props.keyword} items={this.props.multimedia.music_sheets} musicSheets={true}/>
                                }
                            </div>:null}
                        {this.props.multimedia.publications?
                            <div className={"tab-pane fade"+(publicationsActive?" show active":"")} id="nav-publications" role="tabpanel" aria-labelledby="nav-publications-tab">
                                {this.props.paged?
                                    <DocumentsTablePaged url={'search/publications'} params={this.props.params} keyword={this.props.keyword} publications={true} downloadable={this.props.downloadable}></DocumentsTablePaged>:
                                    <DocumentsTable keyword={this.props.keyword} items={this.props.multimedia.publications} publications={true}/>
                                }
                            </div>:null}
                        {this.props.multimedia.videos?
                            <div className={"tab-pane fade"+(videosActive?" show active":"")} id="nav-videos" role="tabpanel" aria-labelledby="nav-videos-tab">
                                {this.props.paged?
                                    <MediaTablePaged url={'search/videos'} params={this.props.params} keyword={this.props.keyword} video={true}></MediaTablePaged>:
                                    <MediaTable keyword={this.props.keyword} items={this.props.multimedia.videos} video={true}/>
                                }
                            </div>:null}
                        {this.props.multimedia.historic_archives?
                            <div className={"tab-pane fade"+(historicActive?" show active":"")} id="nav-historic" role="tabpanel" aria-labelledby="nav-historic-tab">
                                {this.props.paged?
                                    <DocumentsTablePaged url={'search/historicarchives'} params={this.props.params} keyword={this.props.keyword} historicArchives={true}></DocumentsTablePaged>:
                                    <DocumentsTable keyword={this.props.keyword} items={this.props.multimedia.historic_archives} historicArchives={true}/>
                                }
                            </div>:null}
                    </div>
                </div>
            );
        } else {
            return this.props.noElements();
        }
    }
}
import React, { Component } from 'react';
import { BreadcrumbLink } from '../components'
import { Config } from '../config'

export default class About extends Component {
  render() {
    Config.updateMetaTitle('Acerca de');
    
    return (
      <div className="container mb-5">
        <div className="breadcrumb">
          <BreadcrumbLink path="" text="Inicio" />
          <h1>Acerca de</h1>
        </div>
        <p>La Fonoteca Nacional es la responsable de resguardar, preservar y difundir el patrimonio sonoro del país,
          realizando cotidianamente acciones que permiten valorar los archivos sonoros como herencia cultural de México.</p>
        <p>Con el propósito de dar a conocer una muestra de la diversidad musical y lingüística de México que contiene su
          acervo, la Fonoteca Nacional diseñó la plataforma Musiteca.mx.</p>
        <p>La Musiteca.mx constituye el esfuerzo más importante de la Secretaría de Cultura para difundir la herencia musical
          y cultural de México a través del conocimiento de nuestra vasta diversidad cultural: géneros musicales, vida y obra
          de destacados compositores y músicos mexicanos y lenguas indígenas en riesgo de extinción.</p>
        <section>
          <p><strong>Musiteca.mx se nutre de los acervos de las siguientes instituciones:</strong></p>
          <ul>
            <li>Archivo General de la Nación</li>
            <li>Canal 22</li>
            <li>Canal Once</li>
            <li>Centro Nacional de Investigación, Documentación e Información Musical Carlos Chávez</li>
            <li>Instituto Nacional de los Pueblos Indígenas</li>
            <li>Dirección General de Culturas Populares, Indígenas y Urbanas</li>
            <li>Festival Internacional Cervantino</li>
            <li>Fonoteca Nacional</li>
            <li>Instituto Nacional de Antropología e Historia</li>
            <li>Instituto Nacional de Bellas Artes</li>
            <li>Sistema Público de Radiodifusión del Estado Mexicano</li>
            <li>Museo del Estanquillo</li>
          </ul>
        </section>
        <section>
          <p><strong>Con la colaboración de:</strong></p>
          <ul>
            <li>Sociedad de Autores y Compositores de México</li>
          </ul>
        </section>
        <section>
          <p><strong>Y el apoyo de:</strong></p>
          <ul>
            <li>Editores Mexicanos de Música, A.C.</li>
            <li>Asociación Mexicana de Productores de Fonogramas y Videogramas</li>
            <li>Sony Music</li>
            <li>Warner Music</li>
            <li>Universal Music</li>
          </ul>
        </section>
        <section>
          <p>La Fonoteca Nacional agradece también a innumerables compositores, intérpretes y productores independientes que
            generosamente apoyan este proyecto.</p>
        </section>
        <hr className="mt-5" />
        <section className="mt-5">
          <p><strong>Política de derechos de autor de Musiteca.mx</strong></p>
          <p>La Musiteca.mx respeta todos los derechos de propiedad intelectual de terceros, comprometiéndose a ayudarlos a
            proteger sus derechos de autor.</p>
          <p>Si usted es titular o representante de derechos de autor y considera que alguno de los contenidos disponibles en
            la Musiteca.mx se encuentra en este supuesto, por favor háganoslo saber.</p>
          <p>Es oportuno hacer de su conocimiento que el equipo de la Musiteca ha realizado una búsqueda exhaustiva y todo lo
            posible para localizar a los titulares de derechos de autor para solicitarles su autorización. En algunos casos,
            fue imposible localizarlos.&nbsp; Para presentar una petición y ayudarle a subsanar una supuesta infracción a los
            derechos de autor, puede enviarla al representante de derechos de autor designado por la Musiteca, al siguiente
            correo electrónico:&nbsp;<a href="mailto:musitecamx@cultura.gob.mx">musitecamx@cultura.gob.mx</a></p>
        </section>
        <section>
          <p>La petición sobre la supuesta infracción de derechos de autor debe incluir lo siguiente:</p>
          <ol>
            <li><small>Información de contacto de la parte reclamante, nombre completo, dirección, número de teléfono y dirección de
              correo electrónico.</small></li>
            <li><small>Identificación específica de cada contenido cuyos derechos de autor hayan sido supuestamente infringidos.</small></li>
            <li><small>Información mediante la cual la parte reclamante acredite ser la/el titular del derecho supuestamente
              infringido, o su agente autorizado.</small></li>
            <li><small>Una descripción de dónde se haya localizado en la Musiteca.mx el (los) contenido (s) cuyos derechos de autor
              se consideran infringidos (por favor provea el máximo detalle posible y facilite una URL para ayudarnos a
              localizar el material que está denunciando).</small></li>
            <li><small>Una declaración, bajo protesta de decir verdad, conforme la cual la parte reclamante afirme que el uso del (de
              los) contenido(s) en la Musiteca.mx no está autorizado por el titular de los derechos, su agente
              autorizado&nbsp;o la ley.</small></li>
            <li><small>Firma autógrafa o electrónica del titular de los derechos de autor supuestamente infringidos (o de la persona
              autorizada para actuar en su nombre).</small></li>
          </ol>
        </section>
      </div>
    );
  }
}
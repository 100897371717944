import React, { useEffect } from 'react'
import ReactGA from "react-ga4"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { Config } from '../../config'
import useSharedFormState from '../../store';

const PlayButton = (props) => {
    const { setVideo, setIsVideoOpen, player, audioList, setAudioList } = useSharedFormState();

    const playAudio = () => {
        if(Config.isVideo(props.file)) {
            setVideo({
                title: props.title,
                file: props.file
            });
            setIsVideoOpen(true);
            if(player) player.pause();

            fetch(Config.API_URL + 'multimedia/reproduction/' + props.id + '?p=web')
                .then(res => res.json())
                .then(
                    (data) => {},
                    (error) => {}
                );
            ReactGA.event({
                category: 'Video',
                action: 'Reproducir',
                value: parseInt(props.id),
                label: props.title
            });
        } else {
            var index = -1;
            for(var i in audioList) {
                if(audioList[i].id === props.id) index = i;
            }
            if(index === -1) {
                let newAudios = [
                    ...audioList,
                    {
                        id: props.id,
                        name: props.title + (props.movement?' - '+props.movement:''),
                        singer: props.author,
                        musicSrc: `${Config.ASSETS_URL}${props.file}`
                    }
                ];
                setAudioList(newAudios);
                player.playByIndex(newAudios.length - 1);
    
                fetch(Config.API_URL + 'multimedia/reproduction/' + props.id + '?p=web')
                    .then(res => res.json())
                    .then(
                        (data) => {},
                        (error) => {}
                    );
            } else {
                player.playByIndex(index);
            }
            ReactGA.event({
                category: 'Audio',
                action: 'Reproducir',
                value: props.id,
                label: props.title
            });
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if(props.autoplay) playAudio();
        }, 2000);
    },[]);

    return (
        <button className="btn btn-link btn-media-action" title="Reproducir" onClick={playAudio}>
            <FontAwesomeIcon icon={faPlay} size="2x" />
        </button>
    )
}

export default PlayButton;
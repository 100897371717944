import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4"
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbLink, MultimediaTabs } from '../components'
import { Config } from '../config'

const Search = () => {
    const location = useLocation()

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isBook, setIsBook] = useState(false);
    const [bookCover, setBookCover] = useState('');
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [searchedText, setSearchedText] = useState('');
    const [results, setResults] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const text = queryParams.get("t");
        setIsBook(false);
        setBookCover('');
        setIsDownloadable(false);
        setSearchedText(text);

        if(text === "La danza de Moctezuma" || text == "Los surcos de la memoria") {
            Config.updateMetaTitle('Libro '+text);
            setIsBook(true);
            if(text == "Los surcos de la memoria") {
                setBookCover('portada-libro-los-surcos-de-la-memoria.jpg');
                setIsDownloadable(true);
            }
        } else {
            Config.updateMetaTitle('Resultados de búsqueda');
            Config.updateMetaDescription('Resultados de búsqueda para '+text);
            setIsBook(false);
        }

        if(text) {
            fetch(Config.API_URL + 'search?t=' + text)
                .then(res => res.json())
                .then(
                    (data) => {
                        setIsLoaded(true);
                        setResults(data);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                );
            
            ReactGA.event({
                category: 'Busqueda',
                action: 'Resultados',
                label: text
            });
        }
    }, [location]);

    if (error) {
        return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="text-center mt-5">Cargando...</div>;
    } else {
        
        return (
            <div className="container">
                {isBook?
                    <div className="breadcrumb">
                        <h1>Libro {searchedText}</h1>
                    </div>:
                    <div className="breadcrumb">
                        <BreadcrumbLink path="" text="Inicio" />
                        <BreadcrumbLink path="" text="Búsqueda" />
                        <h1>Resultados para '{searchedText}'</h1>
                    </div>
                }
                {bookCover?
                    <div className="text-center">
                        <img className="book-cover" src={require('../images/'+bookCover).default} height="30" alt={"Portada "+searchedText} />
                    </div>:
                    null
                }
                <div className="row">
                    <div className="col-12">
                        <MultimediaTabs keyword={searchedText} multimedia={results} paged={true} params={'t='+searchedText} downloadable={isDownloadable} noElements={() => {return (<p>Lo sentimos, no encontramos resultados para tu búsqueda, inténtalo nuevamente o <Link to="/">ve al inicio</Link> para conocer todos los contenidos la Musiteca.</p>);}} />
                    </div>
                </div>
            </div>
        );
    }
}
export default Search;
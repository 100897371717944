import React from 'react'
import ReactGA from "react-ga4"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Config } from '../../config'
import useSharedFormState from '../../store';
import { useAlert } from 'react-alert'

const AddAudioButton = (props) => {
    const { audioList, setAudioList } = useSharedFormState();

    const addAudio = () => {
        var index = -1;
        for(var i in audioList) {
            if(audioList[i].id === props.id) index = i;
        }
        if(index === -1) {
            setAudioList([
                ...audioList,
                {
                    name: props.title,
                    singer: props.author,
                    musicSrc: `${Config.ASSETS_URL}${props.file}`
                }
            ]);

            fetch(Config.API_URL + 'multimedia/reproduction/' + props.id + '?p=web')
                .then(res => res.json())
                .then(
                    (data) => {},
                    (error) => {}
                );
            ReactGA.event({
                category: 'Audio',
                action: 'Agregar a lista',
                value: parseInt(props.id),
                label: props.title
            });
            alert.success('La pista se agregó con éxito a la lista de reproducción')
        } else {
            alert.show('La pista ya existe en tu lista de reproducción')
        }
    }

    const alert = useAlert();
    return (
        <button className="btn btn-link btn-media-action" title="Agregar a la lista de reproducción actual" onClick={addAudio}>
            <FontAwesomeIcon icon={faPlus} size="2x" />
        </button>
    )
}

export default AddAudioButton;
import React, { useEffect, useState, useRef } from 'react'
import ReactGA from "react-ga4"
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loading, TotalCountItems } from '../../components'
import Gallery from 'react-grid-gallery';
import { Config } from '../../config'
import useSharedFormState from '../../store';

const ImageGridPaged = (props) => {
    const { setAreMediaDetailsOpen, setMultimediaDetails, setAltAuthorColumnTitle, setAltYearColumnTitle } = useSharedFormState();

    const [page, setPage] = useState(0);
    const [length, setLength] = useState(10);
    const [fetching, setFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);
    const [urlChange, setUrlChange] = useState(0); // Contador para resetear imágenes cuando el url cambia

    const isFirstRender = useRef(true);

    // useEffect(() => {
    //     loadData();
    // },[]);

    useEffect(() => {
        loadData();
    },[urlChange]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }

        setItems([]);
        setPage(0);
        setFetching(false);
        setHasMore(true);
        setUrlChange(urlChange + 1);
    },[props.url,props.params]);

    const loadData = () => {
        if(fetching) return;
    
        setFetching(true);
        
        fetch(Config.API_URL + props.url + '?p='+page+'&l='+length+(props.params?'&'+props.params:''))
            .then(res => res.json())
            .then(
                (data) => {
                    setItems([...items, ...data]);
                    setPage(page + 1);

                    if(data.length < length) setHasMore(false);

                    setFetching(false);
                },
                (error) => {
                    setFetching(false);
                    setError(error);
                }
            );
    };

    const showImageDetail = (id) => {
        fetch(Config.API_URL + 'multimedia/detail/' + id)
          .then(res => res.json())
          .then(
              (data) => {
                    setMultimediaDetails(data);
                    setAltAuthorColumnTitle(true);
                    setAltYearColumnTitle(false);
                    setAreMediaDetailsOpen(true);

                    ReactGA.event({
                        category: 'Multimedia',
                        action: 'Ver ficha',
                        value: parseInt(id),
                        label: data.internal_id
                    });
                    ReactGA.event({
                        category: 'Galería',
                        action: 'Ver ficha',
                        value: parseInt(id),
                        label: data.internal_id
                    });
              },
              (error) => {
                setAreMediaDetailsOpen(false);
              }
          )
    }

    const IMAGES = items.map((iconography,index) => {
        
        var title = iconography.title;
        if(props.keyword){
            var searchRegex = new RegExp('('+props.keyword+')' , 'ig');
            if(title) title = title.replace(searchRegex, '<span class="highlight">$1</span>');
        }

        return (
            {
                src: `${Config.ASSETS_URL}${iconography.file}`,
                thumbnail: `${Config.ASSETS_URL}${iconography.file}`,
                // thumbnailWidth: 320,
                // thumbnailHeight: 174,
                // isSelected: true,
                alt: iconography.title,
                caption: (
                    <div>
                        <p className={'text-center'} dangerouslySetInnerHTML={{__html:title}}></p>
                        <button className='btn btn-sm btn-primary' onClick={() => showImageDetail(iconography.id)} style={{position: 'absolute',top: 5,left: 0}}>Ver créditos</button>
                    </div>
                ),
                thumbnailCaption: props.thumbnailCaption?iconography.title:'',
                customOverlay: (
                    <div className="custom-overlay__caption">
                      <div dangerouslySetInnerHTML={{__html:title}}></div>
                    </div>
                ),
            }
        );
    });

    return (
        <div>
            <InfiniteScroll
                dataLength={items.length}
                next={loadData}
                hasMore={hasMore}
                loader={<Loading />}
            >
                <Gallery images={IMAGES} backdropClosesModal={true} enableImageSelection={false} imageCountSeparator={' de '} margin={15} rowHeight={150} />
                <div className="clearfix"></div>
            </InfiniteScroll>
            <TotalCountItems items={items}/>
        </div>
    );
}

export default ImageGridPaged;
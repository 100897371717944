import React, { useState } from 'react'
import Modal from 'react-modal';
import { Config } from '../../config'
import useSharedFormState from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FileModal = () => {
    const { fileIsOpen, setIsFileOpen, file, pageNumber, setPageNumber, setAreMediaDetailsOpen, setMultimediaDetails } = useSharedFormState();

    const step = 0.5;
    const initialZoom = 1;
    const zoomMin = 0.5;
    const zoomMax = 4;

    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(initialZoom);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    function closeModal() {
      setIsFileOpen(false);
      setScale(initialZoom);
    }

    const customStyles = {
      content: {
        top: 'auto',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, 5%)',
        border: 'none',
        borderRadius: '0',
        width: '65%',
        height: '90vh',
        maxHeight: '90vh',
        textAlign: 'center',
        paddingTop: '10px',
        overflow: 'hidden',
      },
    };

    const back = () => {
      setPageNumber(pageNumber - 1)
    }
    const forward = () => {
      setPageNumber(pageNumber + 1)
    }
    const goTo = (event) => {
      if(event.target.value >= 1 && event.target.value <= numPages) setPageNumber(parseInt(event.target.value))
    }
    const zoomOut = () => {
      setScale(scale - step)
    }
    const zoomIn = () => {
      setScale(scale + step)
    }

    const showFileDetail = (id) => {
      fetch(Config.API_URL + 'multimedia/detail/' + id)
        .then(res => res.json())
        .then(
            (data) => {
                  setMultimediaDetails(data);
                  setAreMediaDetailsOpen(true);
            },
            (error) => {
              setAreMediaDetailsOpen(false);
            }
        )
    }
    
    return (
        <Modal isOpen={fileIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Documento">
            <button className="btn btn-link btn-close" onClick={closeModal}></button>
            
            <h6><span>{file.title}</span></h6>
            
            {file.file?(
              <div className="PdfContainer" style={{position: 'relative'}}>
                <Document file={{ url:`${Config.DOCUMENTS_URL}${file.file}`}} loading="Cargando..." onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} height={window.innerHeight*.75} scale={scale} />
                </Document>
                {/* <div style={{position:'absolute',top:0,left:0,right:0,height:window.innerHeight*.75}}></div> */}
                
                <button className='btn btn-sm btn-link' onClick={() => showFileDetail(file.id)} style={{position: 'absolute',bottom: -5,left: -5}}>Ver créditos</button>
                
                <p>Página <input type="number" value={pageNumber} onChange={goTo} min={1} max={numPages} /> de {numPages}</p>
                <button className={'btn btn-link PdfNavigation PdfNavigation-Left'+(pageNumber===1?' d-none':'')} onClick={back}>
                  <FontAwesomeIcon icon={faChevronLeft} size="3x" />
                </button>
                <button className={"btn btn-link PdfNavigation PdfNavigation-Right"+(pageNumber===numPages?' d-none':'')} onClick={forward}>
                  <FontAwesomeIcon icon={faChevronRight} size="3x" />
                </button>
                <button className={"btn btn-link PdfZoom PdfZoomOut"+(scale===zoomMin?' d-none':'')} onClick={zoomOut}>
                  <FontAwesomeIcon icon={faSearchMinus} size="2x" />
                </button>
                <button className={"btn btn-link PdfZoom PdfZoomIn"+(scale===zoomMax?' d-none':'')} onClick={zoomIn}>
                  <FontAwesomeIcon icon={faSearchPlus} size="2x" />
                </button>
              </div>
              // <iframe src={`${Config.ASSETS_URL}${file.file}`} className="" width="100%" height="100%"></iframe>
            ):false}
        </Modal>
    )
}

export default FileModal;
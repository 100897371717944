import React from 'react'
import ReactGA from "react-ga4"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import useSharedFormState from '../../store';

const SeeDocumentButton = (props) => {
    const { setImage, setIsImageOpen, setFile, setIsFileOpen, setPageNumber, setAltYearColumnTitle } = useSharedFormState();

    const showFile = () => {
        var fileExt = props.file.split('.').pop();
        if(fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif') {
            setImage({
                title: props.title,
                file: props.file
            });
            setIsImageOpen(true);

            ReactGA.event({
                category: 'Imagen',
                action: 'Ver',
                value: props.id,
                label: props.title
            });
        } else {
            setFile({
                id: props.id,
                title: props.title,
                file: props.file
            });
            setIsFileOpen(true);
            setPageNumber(1);

            ReactGA.event({
                category: 'Documento',
                action: 'Ver',
                value: props.id,
                label: props.title
            });
        }
        setAltYearColumnTitle(props.altYearColumnTitle);
    }

    return (
        <button id={"show-file-"+props.id} className="btn btn-link btn-media-action" title="Abrir documento" onClick={showFile}>
            <FontAwesomeIcon icon={faFileAlt} size="2x" />
        </button>
    )
}

export default SeeDocumentButton;
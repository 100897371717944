import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { BreadcrumbLink, SectionCard } from '../components'
import { Config } from '../config'

const Live = () => {
    const location = useLocation();

    let { url } = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [live, setLive] = useState([]);

    useEffect(() => {
      var webservice = 'current';
      if(url === 'proximos'){
          webservice = 'upcoming';
      } else if(url === 'pasados') {
          webservice = 'previous';
      }
      fetch(Config.API_URL + 'lives/' + webservice + '?p=0&l=10')
          .then(res => res.json())
          .then(
              (data) => {
                  setIsLoaded(true);
                  setLive(data);
              },
              (error) => {
                  setIsLoaded(true);
                  setError(error);
              }
          );
    }, [location,url]);

    let main = <div className="row">
                  {live.length?'':<p className="text-center h4 mt-3">Por el momento, no hay eventos {!url?'ocurriendo':url}.</p>}   
                  {live.map((live,index) => {
                    var date = Config.formatLiveDate(live);
                    return (
                      <div className="col-12 col-md-4" key={`live-${index}`}>
                        <SectionCard classes="card-essential" section="en-directo" url={`evento/${live.url}`} name={live.name} image={live.image} imagepath="lives" description={date} />
                      </div>
                    );
                  })}
                </div>

    let currentContent = main;

    if (error) {
       return <div className="text-center">Error: {error.message}</div>;
    } else if (!isLoaded) {
       return <div className="text-center mt-5">Cargando...</div>;
    } else {
      const metatitle = (url==='proximos'?'Eventos Próximos':(url==='pasados'?'Eventos Pasados':'Eventos En Directo'));

      return (
        <div>
            <Helmet>
                <title>Musiteca | {metatitle}</title>
                <meta property="og:title" content={"Musiteca - " + metatitle} />
                <meta name="twitter:title" content={"Musiteca - " + metatitle} />
            </Helmet>
            <div className="container">
                <section id="live">
                    <div className="breadcrumb">
                        <BreadcrumbLink path="" text="Inicio" />
                        {url==='proximos' || url==='pasados'?<BreadcrumbLink path="en-directo" text="En Directo" />:<h1>En Directo</h1>}
                        {url==='proximos'?<h1>Próximos</h1>:(url==='pasados'?<h1>Pasados</h1>:null)}
                    </div>
                    <div className="mb-2">
                      <blockquote>
                        <i>En Directo</i> reúne videograbaciones de sesiones de escucha, conferencias, presentaciones y otros 
                        eventos que tienen lugar en la Fonoteca Nacional.
                      </blockquote>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-2">
                        <div className="list-group list-live-group">
                          <Link key={`live-option-current`} to={`/en-directo`} className={"list-group-item list-group-item-action list-live-type"+(!url?' active':'')}>
                              En vivo
                          </Link>
                          <Link key={`live-option-upcoming`} to={`/en-directo/proximos`} className={"list-group-item list-group-item-action list-live-type"+(url==='proximos'?' active':'')}>
                              Próximos
                          </Link>
                          <Link key={`live-option-previous`} to={`/en-directo/pasados`} className={"list-group-item list-group-item-action list-live-type"+(url==='pasados'?' active':'')}>
                              Pasados
                          </Link>
                        </div>
                      </div>
                      <div className="col-12 col-lg-10">
                        {currentContent}
                      </div>
                    </div>
                </section>
            </div>
        </div>
      );
    }
}

export default Live;